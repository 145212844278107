import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { BackendMetricPeriodType } from '@squidcloud/console-common/types/metrics.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  newErrorsColumn,
  newMedianColumn,
  newP95Column,
  newSuccessColumn,
  newTextColumn,
} from '@squidcloud/console-web/app/backend/backend-component-utils';

@Component({
  templateUrl: './triggers.component.html',
  styleUrls: ['./triggers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TriggersComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;
  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(private readonly backendService: BackendService) {
    this.backendService.reportBackendTabHasMetrics(false);

    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['trigger'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions
            .sort((m1, m2) => {
              const extras1 = m1.functionData.extras;
              const extras2 = m2.functionData.extras;
              const integrationId1 = extras1['integrationId'] as string;
              const integrationId2 = extras2['integrationId'] as string;
              let result = integrationId1.localeCompare(integrationId2);
              if (result === 0) {
                const collectionName1 = extras1['collectionName'] as string;
                const collectionName2 = extras2['collectionName'] as string;
                result = collectionName1.localeCompare(collectionName2);
                if (result === 0) {
                  const mutations1 = extras1['mutationTypes'] as string[];
                  const mutations2 = extras2['mutationTypes'] as string[];
                  result = mutations1.join().localeCompare(mutations2.join());
                  if (result === 0) {
                    const functionName1 = m1.functionData.serviceFunctionName;
                    const functionName2 = m2.functionData.serviceFunctionName;
                    result = functionName1.localeCompare(functionName2);
                  }
                }
              }
              return result;
            })
            .map(metric => {
              const { counts, functionData } = metric;
              const { extras } = functionData;
              const functionName = functionData.serviceFunctionName;
              const columns: Array<BundleDataTableColumn> = [
                newTextColumn(extras['integrationId'] as string),
                newTextColumn(extras['collectionName'] as string),
                newTextColumn((extras['mutationTypes'] as string[]).join(', ')),
                newSuccessColumn(counts),
                newErrorsColumn(counts),
                newMedianColumn(counts),
                newP95Column(counts),
                newTextColumn(functionName),
              ];
              return { columns };
            });

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;
          return {
            headerRow: [
              'Integration',
              'Collection',
              'Mutations',
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Function',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );
  }
}

@let agent = agent$ | async;

@if (agent) {
  <h1>{{agentId}} {{agent.modelName}}</h1>

  <mat-tab-group
    #tabGroup
    (selectedTabChange)="changeUrl(tabGroup.selectedIndex)"
    [selectedIndex]="selectedIndex"
    animationDuration="0ms"
    color="primary"
    disableRipple="true"
    mat-align-tabs="start"
    mat-stretch-tabs="false"
  >
    @for (tab of tabs; track tab.id) {
      <mat-tab>
        <ng-template matTabLabel>
          <div [attr.data-testid]="tab.id+'-tab-label'">{{ tab.name }}</div>
        </ng-template>
        <ng-template matTabContent>
          <ng-container *ngComponentOutlet="tab.component"></ng-container>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>
}

import { Injectable } from '@angular/core';
import {
  ApiInjectionSchema,
  ApiParameterLocation,
  ApiResponseParameterLocation,
  HttpMethod,
  Squid,
} from '@squidcloud/client';
import { assertTruthy, truthy } from 'assertic';
import { ApplicationService } from '../../../application/application.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { IntegrationService } from '../../integration.service';
import { BaseSchemaService } from '../base-schema.service';
import { findAvailableDuplicateFieldName } from '../utils';
import {
  DiscoverOpenApiSchemaResponse,
  IntegrationApiSchema,
} from '@squidcloud/internal-common/types/integrations/api.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { cloneDeep } from '@squidcloud/internal-common/utils/object';
import { HttpApiIntegrationConfig } from '@squidcloud/internal-common/types/integrations/schemas';
import { normalizeJsonAsString } from '@squidcloud/internal-common/utils/serialization';

export type ApiTransactionType = 'request' | 'response';

@Injectable({
  providedIn: 'root',
})
export class ApiSchemaService extends BaseSchemaService<HttpApiIntegrationConfig, IntegrationApiSchema> {
  constructor(
    applicationService: ApplicationService,
    integrationService: IntegrationService,
    snackBar: SnackBarService,
    squid: Squid,
  ) {
    super(applicationService, integrationService, snackBar, squid);
  }

  override getEmptySchema(): IntegrationApiSchema {
    return {
      type: 'api',
      baseUrl: '',
      endpoints: {},
    };
  }

  setEmptySchema(): void {
    this.schemaSubject.next(this.getEmptySchema());
  }

  override async discoverSchema(autosave: boolean = false): Promise<IntegrationApiSchema | undefined> {
    if (!this.isOpenApiIntegration) return undefined;
    assertTruthy(this.integration, `'integration' is not defined`);
    const application = this.applicationService.getCurrentApplicationOrFail();

    const configuration = truthy(this.integration.configuration, `'configuration' is not defined`);
    const discovery = await callBackendExecutable(this.squid, 'discoverOpenApiSchema', {
      integrationId: this.integration.id,
      integrationType: 'api',
      discoveryOptions: configuration.discoveryOptions,
      appId: application.appId,
    });

    const schema = await this.setSchema(discovery);
    if (autosave) await this.saveSchema();

    return schema;
  }

  async discoverApiSchemaFromFile(file: File): Promise<IntegrationApiSchema | undefined> {
    assertTruthy(this.integration, `'integration' is not defined`);

    const application = this.applicationService.getCurrentApplicationOrFail();

    const discovery = await callBackendExecutable(
      this.squid,
      'discoverOpenApiSchemaFromFile',
      {
        integrationId: this.integration.id,
        integrationType: 'api',
        appId: application.appId,
      },
      file,
    );

    return this.setSchema(discovery);
  }

  get isOpenApiIntegration(): boolean {
    return !!this.integration?.configuration?.discoveryOptions.openApiSpecUrl;
  }

  setBaseUrl(baseUrl: string): void {
    const schema = this.getSchema();
    schema.baseUrl = baseUrl;
    this.modifications.modifyPath(['baseUrl', baseUrl]);
    this.schemaSubject.next(schema);
  }

  setEndpoint(
    currentId: string | undefined,
    newId: string,
    relativePath: string,
    method: HttpMethod,
    description: string | undefined,
    bodyMimeType: string | undefined,
  ): void {
    const schema = this.getSchema();
    const endpoints = schema.endpoints || {};
    const isEdit = currentId !== undefined;

    if (isEdit) {
      if (newId !== currentId) {
        endpoints[newId] = endpoints[currentId];
        delete endpoints[currentId];
        this.modifications.clearPath(currentId);
      }
    }
    const endpoint = endpoints[newId] || {};
    endpoint.relativePath = relativePath;
    endpoint.method = method;
    endpoint.description = description;
    endpoint.bodyMimeType = bodyMimeType;
    endpoints[newId] = endpoint;
    schema.endpoints = endpoints;

    this.schemaSubject.next(schema);
    this.modifications.modifyPath(['endpoint', newId, 'details']);
  }

  deleteEndpoint(endpointId: string): void {
    const schema = this.getSchema();
    delete schema.endpoints[endpointId];
    this.modifications.modifyPath(['endpoint', endpointId]);
    this.schemaSubject.next(schema);
  }

  setRequestField(
    endpointId: string,
    currentName: string | undefined,
    newName: string,
    location: ApiParameterLocation | undefined,
    description: string | undefined,
  ): void {
    const schema = this.getSchema();
    const endpoint = truthy(schema.endpoints[endpointId]);
    const isEdit = currentName !== undefined;
    const properties = endpoint.requestSchema || {};

    if (isEdit) {
      if (newName !== currentName) {
        properties[newName] = properties[currentName];
        delete properties[currentName];
      }
    }

    const field = properties[newName] || {};

    if (location) field.location = location;
    if (description) field.description = description;

    properties[newName] = field;
    endpoint.requestSchema = properties;

    this.modifications.modifyPath(['endpoint', endpointId, 'request']);
    this.schemaSubject.next(schema);
  }

  setResponseField(
    endpointId: string,
    currentName: string | undefined,
    newName: string,
    location: ApiResponseParameterLocation | undefined,
    path: string | undefined,
    description: string | undefined,
  ): void {
    const schema = this.getSchema();
    const endpoint = truthy(schema.endpoints[endpointId]);
    const isEdit = currentName !== undefined;
    const properties = endpoint.responseSchema || {};

    if (isEdit) {
      if (newName !== currentName) {
        properties[newName] = properties[currentName];
        delete properties[currentName];
      }
    }

    const field = properties[newName] || {};

    if (location) field.location = location;
    if (path) field.path = path;
    if (description) field.description = description;

    properties[newName] = field;
    endpoint.responseSchema = properties;

    this.modifications.modifyPath(['endpoint', endpointId, 'response']);
    this.schemaSubject.next(schema);
  }

  setEndpointInjectionSchema(endpointId: string, injectionSchema: ApiInjectionSchema): void {
    const schema = this.getSchema();
    const endpoint = truthy(schema.endpoints[endpointId]);
    endpoint.injectionSchema = injectionSchema;
    this.modifications.modifyPath(['endpoint', endpointId, 'injection']);
    this.schemaSubject.next(schema);
  }

  setBaseUrlInjectionSchema(baseUrl: string, injectionSchema: ApiInjectionSchema): void {
    const schema = this.getSchema();
    schema.injectionSchema = injectionSchema;
    this.modifications.modifyPath(['baseUrl', baseUrl, 'injection']);
    this.schemaSubject.next(schema);
  }

  duplicateBaseUrlField(baseUrl: string, fieldName: string): void {
    const schema = this.getSchema();
    const properties = schema.injectionSchema || {};
    const duplicateName = findAvailableDuplicateFieldName(properties, fieldName);
    properties[duplicateName] = cloneDeep(properties[fieldName]);
    this.modifications.modifyPath(['baseUrl', baseUrl, 'injection']);
    this.schemaSubject.next(schema);
  }

  duplicateEndpointField(endpointId: string, fieldName: string, transactionType: ApiTransactionType): void {
    const schema = this.getSchema();
    const endpoint = schema.endpoints[endpointId];
    let properties;
    switch (transactionType) {
      case 'request':
        properties = endpoint.requestSchema || {};
        break;
      case 'response':
        properties = endpoint.responseSchema || {};
        break;
    }
    const duplicateName = findAvailableDuplicateFieldName(properties, fieldName);
    properties[duplicateName] = cloneDeep(properties[fieldName]);
    this.modifications.modifyPath(['endpoint', endpointId, transactionType]);
    this.schemaSubject.next(schema);
  }

  deleteBaseUrlField(baseUrl: string, fieldName: string): void {
    const schema = this.getSchema();
    delete schema.injectionSchema?.[fieldName];
    this.modifications.modifyPath(['baseUrl', baseUrl, 'injection']);
    this.schemaSubject.next(schema);
  }

  deleteEndpointField(endpointId: string, fieldName: string, transactionType: ApiTransactionType): void {
    const schema = this.getSchema();
    switch (transactionType) {
      case 'request':
        delete schema.endpoints[endpointId].requestSchema?.[fieldName];
        break;
      case 'response':
        delete schema.endpoints[endpointId].responseSchema?.[fieldName];
        break;
    }
    this.modifications.modifyPath(['endpoint', endpointId, transactionType]);
    this.schemaSubject.next(schema);
  }

  private async setSchema(discovery: DiscoverOpenApiSchemaResponse): Promise<IntegrationApiSchema> {
    const currentSchema = this.schemaSubject.value;
    const schema: IntegrationApiSchema = currentSchema || {
      type: 'api',
      baseUrl: '',
      endpoints: {},
    };

    // Set initial endpoint modifications.
    for (const [endpointId, endpoint] of Object.entries(discovery?.schema.endpoints || {})) {
      const previousEndpoint = currentSchema?.endpoints[endpointId];
      schema.endpoints[endpointId] = endpoint;
      if (normalizeJsonAsString(previousEndpoint) === normalizeJsonAsString(endpoint)) {
        continue;
      }
      this.modifications.modifyPath(['endpoint', endpointId]);
      if (normalizeJsonAsString(previousEndpoint?.requestSchema) !== normalizeJsonAsString(endpoint.requestSchema)) {
        this.modifications.modifyPath(['endpoint', endpointId, 'request']);
      }
      if (normalizeJsonAsString(previousEndpoint?.responseSchema) !== normalizeJsonAsString(endpoint.responseSchema)) {
        this.modifications.modifyPath(['endpoint', endpointId, 'response']);
      }
      if (
        normalizeJsonAsString(previousEndpoint?.injectionSchema) !== normalizeJsonAsString(endpoint.injectionSchema)
      ) {
        this.modifications.modifyPath(['endpoint', endpointId, 'injection']);
      }
    }
    // Set initial baseUrl modifications.
    if (discovery?.schema.baseUrl) {
      schema.baseUrl = discovery.schema.baseUrl;
      this.modifications.modifyPath(['baseUrl', schema.baseUrl]);

      if (discovery?.schema.injectionSchema) {
        schema.injectionSchema = discovery.schema.injectionSchema;
        this.modifications.modifyPath(['baseUrl', schema.baseUrl, 'injection']);
      }
    }

    this.schemaSubject.next(schema);
    return schema;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlobalUiService } from '../../global/services/global-ui.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { copyText } from '../../utils/copy-utils';
import { OrganizationService } from '../organization.service';
import { Organization, OrganizationId } from '@squidcloud/console-common/types/organization.types';
import { getMessageFromError } from 'assertic';

@Component({
  selector: 'organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OrganizationDetailsComponent {
  organizationObs = this.organizationService.observeCurrentOrganization();

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly snackBar: SnackBarService,
    private readonly globalUiService: GlobalUiService,
  ) {}

  showUpdateOrganizationNameDialog(organization: Organization): void {
    this.globalUiService
      .showDialogWithForm<{ name: string }>({
        title: 'Organization Name',
        autoFocus: true,
        onSubmit: async res => {
          const name = res.name;
          try {
            await this.organizationService.updateOrganizationName(organization.id, name);
            this.snackBar.success('Organization Name updated');
            return;
          } catch (error) {
            console.log('Error updating organization name', error);
            const message = getMessageFromError(error);
            switch (message) {
              case 'ORG_ALREADY_EXISTS':
                return 'An organization with this name already exists';
              default:
                return 'Unable to change name at this time, please try again';
            }
          }
        },
        textLines: ['This can be your company name or a personal name and can be updated.'],
        submitButtonText: 'Update',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'name',
            label: 'Enter your organization name',
            defaultValue: organization.name,
          },
        ],
      })
      .then();
  }

  showDeleteOrganizationDialog(organization: Organization): void {
    this.globalUiService
      .showDeleteDialog(
        `Unlike other actions, deleting an organization cannot be undone or recovered. This will permanently delete the <span class="bold">${organization.name}</span> organization and delete all applications.`,
        async () => {
          try {
            const errorCode = await this.organizationService.deleteCurrentOrganization();
            if (errorCode !== null) {
              let uiMessage = '';
              switch (errorCode) {
                case 'deletion-is-not-allowed':
                  uiMessage = `The organization can't be deleted`;
                  break;
                case 'external-billing-type':
                  uiMessage = `Organizations created via an external marketplace can't be removed directly. Please contact service support.`;
              }
              this.snackBar.warning(uiMessage);
            } else {
              this.snackBar.success('Organization deleted');
            }
          } catch (e) {
            console.error('Error deleting organization', e);
            this.snackBar.warning('Unable to delete organization. Please try again later.');
            return;
          }
        },
      )
      .then();
  }

  async copyId(id: OrganizationId): Promise<void> {
    await copyText(id);
    this.snackBar.success('Organization ID copied to clipboard');
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DragEvent } from 'react';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FileUploadComponent {
  @Input() fileTypes?: string;
  @Output() fileChange = new EventEmitter<File | undefined>();

  selectedFile?: File;

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileChanged(event.dataTransfer.files?.[0]);
  }

  fileInputChanged($event: Event): void {
    this.fileChanged(($event.target as HTMLInputElement).files?.[0]);
  }

  fileChanged(file: File | undefined): void {
    this.selectedFile = file;
    this.fileChange.emit(file);
  }
}

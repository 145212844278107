import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  filter,
  finalize,
  from,
  map,
  MonoTypeOperatorFunction,
  Observable,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';
import { MetricsService } from '../metric/metrics.service';
import { BackendMetricPeriodType, GetBackendMetricsResponse } from '@squidcloud/console-common/types/metrics.types';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { MILLIS_PER_SECOND } from '@squidcloud/internal-common/types/time-units';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  activeBackendTabHasMetricsSubject = new BehaviorSubject<boolean>(false);
  private readonly metricsObservablesMap = new Map<BackendMetricPeriodType, ActiveSubscription>();

  constructor(
    private readonly metricsService: MetricsService,
    private readonly applicationService: ApplicationService,
  ) {}

  reportBackendTabHasMetrics(hasMetrics: boolean): void {
    this.activeBackendTabHasMetricsSubject.next(hasMetrics);
  }

  get activeBackendTabHasMetrics$(): Observable<boolean> {
    return this.activeBackendTabHasMetricsSubject.asObservable();
  }

  getMetricsObs(
    periodType: BackendMetricPeriodType,
    takeUntilDestroyedOperator: MonoTypeOperatorFunction<unknown>,
  ): Observable<GetBackendMetricsResponse> {
    let subscription = this.metricsObservablesMap.get(periodType);
    if (!subscription) {
      const destroy$ = new Subject<void>();
      subscription = {
        subscriptionCount: 1,
        destroy$,
        observable: timer(0, 10 * MILLIS_PER_SECOND).pipe(
          switchMap(() => this.applicationService.currentApplication$),
          filter(Boolean),
          map(app => app.appId),
          switchMap(appId =>
            from(this.metricsService.getMetrics(appId, periodType)).pipe(
              catchError(error => {
                console.error(`Failed to fetch metrics for appId: ${appId}`, error);
                return EMPTY;
              }),
            ),
          ),
          finalize(() => this.metricsObservablesMap.delete(periodType)),
          shareReplay({ bufferSize: 1, refCount: true }),
          takeUntil(destroy$),
        ) as Observable<GetBackendMetricsResponse>,
      };
      this.metricsObservablesMap.set(periodType, subscription);
    } else {
      subscription.subscriptionCount++;
    }
    const singleUserCompletionEvent$: Observable<void> = new Subject<void>();
    takeUntilDestroyedOperator(singleUserCompletionEvent$).subscribe({
      complete: () => {
        subscription.subscriptionCount--;
        if (subscription.subscriptionCount === 0) {
          subscription.destroy$.next();
          subscription.destroy$.complete();
        }
      },
    });
    return subscription.observable;
  }

  observeMetricByPeriodType(
    periodType$: Observable<BackendMetricPeriodType>,
    takeUntilDestroyed: MonoTypeOperatorFunction<unknown>,
  ): Observable<GetBackendMetricsResponse> {
    return periodType$.pipe(switchMap(periodType => this.getMetricsObs(periodType, takeUntilDestroyed)));
  }
}

interface ActiveSubscription {
  subscriptionCount: number;
  destroy$: Subject<void>;
  observable: Observable<GetBackendMetricsResponse>;
}

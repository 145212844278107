import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';

import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { Observable } from 'rxjs';
import { AiChatbotProfiles } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getSortedKeys } from '@squidcloud/console-web/app/utils/angular-utils';
import { AiChatModelName } from '@squidcloud/client';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudioHomeComponent {
  protected readonly getSortedKeys = getSortedKeys;

  readonly agents$: Observable<AiChatbotProfiles | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
  ) {
    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(() => {
      void this.studioService.initialize();
    });
    this.agents$ = this.studioService.observeAgents();
  }

  get hasAgents(): boolean {
    const schema = this.studioService.getSchemaOrFail();
    return !!Object.keys(schema.profiles).length;
  }

  createAgent(): void {
    this.globalUiService
      .showDialogWithForm<{ id: string; modelName: AiChatModelName; public: boolean; auditLog: boolean }>({
        title: `Add agent?`,
        minRole: 'ADMIN',
        textLines: ['Agents allow you to inject context and instructions into your AI model prompts'],
        submitButtonText: 'Add',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'id',
            label: 'Agent ID',
          },
          {
            type: 'textarea',
            required: true,
            attributes: {
              autosize: true,
              minRows: 10,
              maxRows: 15,
            },
            nameInForm: 'description',
            label: 'Description',
          },
        ],
        onSubmit: async (data): Promise<string | void> => {
          if (!data) return;
          const agentId = data.id;

          try {
            await this.studioService.addAgent(agentId, {
              modelName: 'gpt-4o',
              isPublic: false,
              auditLog: false,
            });
            this.snackBar.success('Agent created');
          } catch (error: unknown) {
            const message = error instanceof Error ? error.message : 'Unable to create profile';
            this.snackBar.warning(message);
          }
        },
      })
      .then();
  }
}

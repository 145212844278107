import { Injectable } from '@angular/core';
import { truthy } from 'assertic';
import { ApplicationService } from '../../../application/application.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { IntegrationService } from '../../integration.service';
import { BaseSchemaService } from '../base-schema.service';
import { findAvailableDuplicateFieldName } from '../utils';
import { DataSchemaFieldType } from '@squidcloud/internal-common/schema/schema.types';
import {
  BaseIntegrationConfig,
  DatabaseIntegrationConfig,
  isNoSqlDatabase,
} from '@squidcloud/internal-common/types/integrations/schemas';
import { IntegrationDataSchema } from '@squidcloud/internal-common/types/integrations/database.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { pascalCase } from 'change-case';
import { cloneDeep, isEqual, isNil } from '@squidcloud/internal-common/utils/object';
import { generateShortId, Squid } from '@squidcloud/client';

@Injectable({
  providedIn: 'root',
})
export class DataSchemaService<
  T extends DatabaseIntegrationConfig = DatabaseIntegrationConfig,
> extends BaseSchemaService<T, IntegrationDataSchema> {
  constructor(
    applicationService: ApplicationService,
    integrationService: IntegrationService,
    snackBar: SnackBarService,
    squid: Squid,
  ) {
    super(applicationService, integrationService, snackBar, squid);
  }

  override getEmptySchema(): IntegrationDataSchema {
    return { type: 'data', collections: {} };
  }

  override async discoverSchema(
    useAiToGenerateDescriptions?: boolean,
    collectionNames?: string[],
  ): Promise<IntegrationDataSchema | undefined> {
    return await this.discoverSchemaInternal(!!useAiToGenerateDescriptions, collectionNames);
  }

  setField(
    collectionName: string,
    currentName: string | undefined,
    newName: string,
    type: DataSchemaFieldType,
    min: number | undefined,
    max: number | undefined,
    defaultValue: string | number | boolean | null | undefined,
    primaryKey: boolean,
    required: boolean,
    description: string | undefined,
  ): void {
    const schema = this.getSchema();
    const collection = truthy(
      schema.collections[collectionName],
      `No collection with name '${collectionName}' found in schema.`,
    );
    collection.properties = collection.properties || {};
    const properties = collection.properties;
    const isEdit = currentName !== undefined;

    if (isEdit) {
      const requiredArray = (collection.required || []) as string[];
      const index = requiredArray.findIndex(requiredField => requiredField === currentName);
      if (index >= 0) {
        requiredArray.splice(index, 1);
      }
      if (newName !== currentName) {
        properties[newName] = properties[currentName];
        delete properties[currentName];
      }
    }

    // TODO: Add data types.
    const field = properties[newName] || {};
    if (type === 'objectId') {
      field.type = 'string';
      field.dataType = 'objectId';
    } else if (type === 'date') {
      field.type = 'object';
      field.isDate = true;
    } else if (type === 'json') {
      field.type = 'object';
      field.isJSON = true;
    } else {
      field.type = type;
    }

    if (min !== undefined || max !== undefined) {
      if (type === 'string') {
        field.minLength = min;
        field.maxLength = max;
      } else {
        field.minimum = min;
        field.maximum = max;
      }
    }

    field.default = defaultValue;
    field.primaryKey = primaryKey;
    field.description = description;

    if (required) {
      const requiredArray = (collection.required || []) as string[];
      requiredArray.push(newName);
      collection.required = requiredArray;
    } else {
      field.nullable = true;
    }

    properties[newName] = field;

    this.modifications.modifyPath(collectionName);
    this.modifications.modifyPath([collectionName, newName]);
    this.schemaSubject.next(schema);
  }

  toggleHiddenField(collectionName: string, fieldName: string): void {
    const schema = this.getSchema();
    const collection = truthy(
      schema.collections[collectionName],
      `No collection with name '${collectionName}' found in schema.`,
    );
    collection.properties = collection.properties || {};
    const properties = collection.properties;

    this.modifications.modifyPath(collectionName);
    this.modifications.modifyPath([collectionName, fieldName]);

    const field = properties[fieldName] || {};
    field.hidden = !field.hidden;

    this.schemaSubject.next(schema);
  }

  addCollectionToSchema(collectionName: string): void {
    const schema = this.getSchema();
    schema.collections[collectionName] = {};
    this.modifications.modifyPath(collectionName);
    this.schemaSubject.next(schema);
  }

  deleteCollectionFromSchema(collectionName: string): void {
    const schema = this.getSchema();
    delete schema.collections[collectionName];
    this.modifications.modifyPath(collectionName);
    this.schemaSubject.next(schema);
  }

  duplicateField(collectionName: string, fieldName: string): void {
    const schema = this.getSchema();
    const collection = schema.collections[collectionName];
    const properties = collection.properties || {};
    const duplicateName = findAvailableDuplicateFieldName(properties, fieldName);
    properties[duplicateName] = cloneDeep(properties[fieldName]);
    if (collection.required?.includes(fieldName)) {
      collection.required = [...collection.required, duplicateName];
    }
    this.modifications.modifyPath(collectionName);
    this.modifications.modifyPath([collectionName, duplicateName]);
    this.schemaSubject.next(schema);
  }

  deleteCollectionField(collectionName: string, fieldName: string): void {
    const schema = this.getSchema();
    const collectionSchema = schema.collections[collectionName];
    delete collectionSchema.properties?.[fieldName];

    // Delete the `required` field if it exists
    const requiredSet = new Set(collectionSchema.required || []);
    requiredSet.delete(fieldName);
    collectionSchema.required = Array.from(requiredSet);

    this.modifications.modifyPath(collectionName);
    this.schemaSubject.next(schema);
  }

  generateTypeScriptTypes(collectionName: string): string | undefined {
    const schema = this.schemaSubject.value;
    if (!schema) {
      return undefined;
    }

    const collectionSchema = schema.collections[collectionName];
    if (!collectionSchema) {
      return undefined;
    }

    const properties = collectionSchema.properties || {};
    const required = collectionSchema.required || [];
    const types = Object.entries(properties).map(([fieldName, fieldSchema]) => {
      const requiredField = required.includes(fieldName);
      let type = fieldSchema.type === 'object' && fieldSchema.isDate ? 'Date' : fieldSchema.type || 'any';
      if (type === 'array') {
        type = '[]';
      }

      const optional = !requiredField ? '?' : '';
      return `\t${fieldName}${optional}: ${type};`;
    });
    return `export interface ${pascalCase(collectionName)} {\n${types.join('\n')}\n}`;
  }

  updateCollection(
    currentCollectionName: string,
    newName: string,
    allowExtraFields = false,
    description?: string,
  ): void {
    const schema = this.getSchema();

    if (currentCollectionName !== newName) {
      this.modifications.clearPath(currentCollectionName);
      schema.collections[newName] = schema.collections[currentCollectionName];
      delete schema.collections[currentCollectionName];
    }

    const newSchema = schema.collections[newName];
    newSchema.additionalProperties = allowExtraFields;
    newSchema.description = description;
    this.modifications.modifyPath(newName);
    this.schemaSubject.next(schema);
  }

  private async discoverSchemaInternal(
    useAiToGenerateDescriptions: boolean,
    collectionNames?: string[],
  ): Promise<IntegrationDataSchema | undefined> {
    const application = this.applicationService.getCurrentApplicationOrFail();

    const discovery = await callBackendExecutable(this.squid, 'discoverDataConnectionSchema', {
      appId: application.appId,
      integrationConfig: this.getCurrentBaseIntegrationConfigForDiscovery(),
      useAi: useAiToGenerateDescriptions,
      collections: collectionNames,
    });

    const isSql = !isNoSqlDatabase(truthy(this.integration?.type));
    const isWholeSchemaRediscovery = !collectionNames || collectionNames.length === 0;

    const currentSchema = this.schemaSubject.value;
    const schema: IntegrationDataSchema = currentSchema || { type: 'data', collections: {} };
    const collectionSchemasInResponse = discovery.schema?.collections || {};
    const discoveredCollectionNames = isWholeSchemaRediscovery
      ? Object.keys(collectionSchemasInResponse)
      : collectionNames;
    for (const collectionName of discoveredCollectionNames) {
      const discoveredCollectionSchema = collectionSchemasInResponse[collectionName];
      const currentCollectionSchema = currentSchema?.collections?.[collectionName];
      if (!currentCollectionSchema) {
        schema.collections[collectionName] = discoveredCollectionSchema;
        this.modifications.modifyPath(collectionName);
      } else if (!isEqual(currentCollectionSchema, discoveredCollectionSchema)) {
        let modificationMade = false;

        const existingCollectionSchema = schema.collections[collectionName];
        if (
          existingCollectionSchema.description !== discoveredCollectionSchema.description &&
          discoveredCollectionSchema.description
        ) {
          existingCollectionSchema.description = discoveredCollectionSchema.description;
          modificationMade = true;
        }

        // Adds any properties that are not in the existing schema. For SQL db's, it will only update type, required,
        // and primaryKey values if they do not match, as well as remove fields which are not in the discovered schema.
        const properties = existingCollectionSchema?.properties;
        if (properties) {
          for (const [key, value] of Object.entries(discoveredCollectionSchema.properties || {})) {
            const currentValue = properties?.[key];
            if (!currentValue) {
              properties[key] = value;
              if (
                discoveredCollectionSchema.required?.includes(key) &&
                !existingCollectionSchema.required?.includes(key)
              ) {
                existingCollectionSchema.required = (existingCollectionSchema.required ?? []).concat(key);
              }
              modificationMade = true;
              this.modifications.modifyPath([collectionName, key]);
            } else if (isSql) {
              if (
                discoveredCollectionSchema.required?.includes(key) &&
                !existingCollectionSchema.required?.includes(key)
              ) {
                existingCollectionSchema.required = (existingCollectionSchema.required ?? []).concat(key);
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              } else if (
                !discoveredCollectionSchema.required?.includes(key) &&
                existingCollectionSchema.required?.includes(key)
              ) {
                existingCollectionSchema.required = existingCollectionSchema.required.filter(
                  element => element !== key,
                );
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              }

              if (currentValue['type'] !== value['type']) {
                properties[key]['type'] = value['type'];
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              }
              if (!!currentValue['primaryKey'] !== !!value['primaryKey']) {
                properties[key]['primaryKey'] = value['primaryKey'];
                modificationMade = true;
                this.modifications.modifyPath([collectionName, key]);
              }
            }
            if (currentValue?.['description'] !== value['description'] && value['description']) {
              properties[key]['description'] = value['description'];
              modificationMade = true;
              this.modifications.modifyPath([collectionName, key]);
            }
          }

          // For SQL integrations, remove any collections which are not discovered.
          if (isSql) {
            for (const key of Object.keys(properties || {})) {
              if (isNil(discoveredCollectionSchema.properties?.[key])) {
                delete properties?.[key];
                modificationMade = true;
              }
            }
          }
        }

        if (modificationMade) {
          this.modifications.modifyPath(collectionName);
        }
      }
    }

    if (isSql && isWholeSchemaRediscovery) {
      for (const [collectionName] of Object.entries(currentSchema?.collections || {})) {
        if (!collectionSchemasInResponse?.[collectionName]) {
          delete currentSchema?.collections[collectionName];
        }
      }
    }

    this.schemaSubject.next(schema);
    return schema;
  }

  getCurrentBaseIntegrationConfigForDiscovery(): BaseIntegrationConfig & { configuration: unknown } {
    return {
      id: `discoverDataConnectionSchema_${generateShortId()}`,
      type: truthy(this.integration?.type),
      configuration: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        connectionOptions: truthy(this.integration as any).configuration?.connectionOptions,
      },
    };
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppId, ENVIRONMENT_IDS, EnvironmentId } from '@squidcloud/client';
import { Organization } from '@squidcloud/console-common/types/organization.types';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';
import { CreateApplicationDialogComponent } from '@squidcloud/console-web/app/application/create-application-dialog/create-application-dialog.component';
import { appIdWithEnvironmentId, parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { getMessageFromError } from 'assertic';
import { firstValueFrom, map } from 'rxjs';
import { AccountService } from '../../../account/account.service';
import { ApplicationService } from '../../../application/application.service';
import { SavePaymentDetailsDialogComponent } from '../../../organization/organization-billing/save-payment-details-dialog/save-payment-details-dialog.component';
import { OrganizationService } from '../../../organization/organization.service';
import { UsageService } from '../../../usage/usage.service';
import { GlobalUiService } from '../../services/global-ui.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { NavigationService } from '@squidcloud/console-web/app/utils/navigation.service';
import { PermissionsService } from '@squidcloud/console-web/app/organization/permissions.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HeaderComponent {
  allAppsObs = this.applicationService.observeApplicationsForCurrentOrganization().pipe(
    map(applications => {
      if (!applications) return undefined;
      return applications.filter(app => parseAppId(app.appId).environmentId === 'prod');
    }),
  );
  allEnvironmentIds = ENVIRONMENT_IDS;
  isOverQuotaObs = this.usageService.observeIsOverQuota();

  constructor(
    protected readonly organizationService: OrganizationService,
    protected readonly applicationService: ApplicationService,
    protected readonly accountService: AccountService,
    private readonly globalUiService: GlobalUiService,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly permissionsService: PermissionsService,
    private readonly snackBar: SnackBarService,
    private readonly dialog: MatDialog,
    private readonly usageService: UsageService,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.router.events.pipe(takeUntilDestroyed()).subscribe(() => this.cdr.detectChanges());
  }

  async switchOrganization(organization: Organization): Promise<void> {
    const navGuard = this.navigationService.newNavigationGuard();
    const applications = await this.applicationService.getApplicationsForOrganizationId(organization.id);
    const currentUrl = this.router.url;
    if (navGuard.hasNotNavigated) {
      if (!applications.length || currentUrl.includes('/organization')) {
        await this.organizationService.switchOrganizationUrl(organization.id);
      } else {
        await this.applicationService.switchApplicationUrl(appIdWithEnvironmentId(applications[0].appId, 'dev'));
      }
    }
  }

  async switchApplication(appId: AppId): Promise<void> {
    if ((await firstValueFrom(this.applicationService.currentApplication$))?.appId !== appId) {
      await this.applicationService.switchApplicationUrl(appIdWithEnvironmentId(appId, 'dev'));
    }
  }

  get isApplicationPage(): boolean {
    return this.router.url.includes('/application/');
  }

  async switchEnvironment(appId: AppId, environmentId: EnvironmentId): Promise<void> {
    await this.applicationService.switchEnvironmentUrl(appId, environmentId);
  }

  dismissPaymentDetailsBanner(): void {
    this.accountService.dismissPaymentDetailsBanner();
  }

  showPaymentDetailsDialog(): void {
    SavePaymentDetailsDialogComponent.show(this.dialog);
  }

  showCreateOrganizationModal(): void {
    this.globalUiService
      .showDialogWithForm<{ name: string }>({
        title: 'Create new org',
        autoFocus: true,
        onSubmit: async res => {
          const name = res['name'];
          try {
            void this.analytics.track('CTA Clicked', {
              title: 'Create',
              location: 'create-organization-modal',
              orgId: undefined,
              appId: undefined,
            });
            const navGuard = this.navigationService.newNavigationGuard();
            const orgId = await this.organizationService.createOrganization(name);
            await this.organizationService.switchOrganization(orgId);
            await navGuard.navigate(['/organization', orgId, 'details']);
          } catch (error) {
            console.error('Failed to create organization', error);
            this.snackBar.warning('Unable to create organization', getMessageFromError(error));
            return;
          }
          this.snackBar.success('Organization created');
        },
        textLines: ['In order to create a new organization we need to start off by giving it a name.'],
        submitButtonText: 'Create',
        formElements: [
          {
            type: 'input',
            required: true,
            nameInForm: 'name',
            label: 'Name your organization',
            hint: 'For personal accounts consider using your name',
          },
        ],
      })
      .then();
  }

  async showCreateApplicationDialog(): Promise<void> {
    if (!(await this.permissionsService.checkCanCreateApplication())) {
      return;
    }
    CreateApplicationDialogComponent.show(this.dialog);
  }
}

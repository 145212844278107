import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { QuickStartTask } from '../quick-start.service';
import { assertTruthy } from 'assertic';

export type TaskDisplayType = 'next_task' | 'task' | 'completed_task';

@Component({
  selector: 'quick-start-task',
  templateUrl: './quick-start-task.component.html',
  styleUrls: ['./quick-start-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class QuickStartTaskComponent implements OnInit, OnChanges {
  @Input({ required: true })
  task!: QuickStartTask;

  @Input({ required: true })
  taskDisplayType!: TaskDisplayType;

  @Output()
  markComplete = new EventEmitter<void>();

  @Output()
  startTask = new EventEmitter<void>();

  ngOnChanges(): void {
    this.checkRequiredInputs();
  }

  ngOnInit(): void {
    this.checkRequiredInputs();
  }

  private checkRequiredInputs(): void {
    assertTruthy(this.task, `'task' must be set`);
    assertTruthy(this.taskDisplayType, `'taskDisplayType' must be set`);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'knowledge-card',
  templateUrl: './knowledge.component.html',
  styleUrl: './knowledge.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class KnowledgeComponent {
  show = true;
  @Input() warning = false;

  @Output() dismiss = new EventEmitter<void>();

  onDismiss(): void {
    this.show = !this.show;
    this.dismiss.emit();
  }
}

@let agent = agent$ | async;

@if (agent) {
  <studio-flyout
    [agentId]="agentId"
    [eligibleAiFunctions]="getEligibleAiFunctions()"
    [eligibleConnectedAgentIds]="getEligibleConnectedAgentIds()"
    (connect)="onConnect($event.type, $event.options)"
  ></studio-flyout>
  <div class="create">
    <div class="left">
      <form [formGroup]="form" class="form">
        @let modelControl = form.controls['model'];
        <mat-form-field
          [hideRequiredMarker]="true"
          [ngClass]="{
            valid: modelControl.valid && modelControl.touched,
          }"
          appearance="outline"
          class="form_field dialog_form_field select_field"
        >
          <mat-label>Model</mat-label>
          <mat-select formControlName="model" class="select_box gray_select">
            @for (model of modelOptions; track model.value) {
              <mat-option [value]="model.value">{{ model.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        @let instructionsControl = form.controls['instructions'];
        <mat-form-field
          [hideRequiredMarker]="true"
          [ngClass]="{
            valid: instructionsControl.valid && instructionsControl.touched,
          }"
          appearance="outline"
          class="form_field dialog_form_field textarea_form_field"
        >
          <mat-label>Instructions</mat-label>
          <textarea
            name="instructions"
            formControlName="instructions"
            placeholder=""
            [cdkTextareaAutosize]="true"
            [cdkAutosizeMinRows]="10"
            autocomplete="off"
            matInput
          ></textarea>
        </mat-form-field>
      </form>
    </div>
    <div class="right">
      <button (click)="addAbility()">Add Ability</button>
      <h3>Connected Agents</h3>
      <ul>
        @for (connectedAgent of agent.connectedAgents; track connectedAgent) {
          <li>
            <span>{{connectedAgent.agentId}} {{connectedAgent.description}}</span>
            <button (click)="onDelete('connected_agent', { connectedAgentId: connectedAgent.agentId })">Remove</button>
          </li>
        }
      </ul>
      <h3>AI Functions</h3>
      <ul>
        @for (fn of agent.functions; track fn) {
          <li>
            <span>{{fn}}</span>
            <button (click)="onDelete('ai_function', { fn: fn })">Remove</button>
          </li>
        }
      </ul>
      <h3>Knowledge Base</h3>
      <ul>
        @for (entry of getEntries(agent.contexts); track entry) {
          <li>
            <span>{{entry[1].title}} {{entry[1].text}}</span>
            <!-- Defaults to 'text' if the context type is not defined.-->
            <button (click)="onDelete(entry[1].type || 'text', { contextId: entry[0] })">Remove</button>
          </li>
        }
      </ul>
    </div>
  </div>
}

import { Pipe, PipeTransform } from '@angular/core';
import { AppId } from '@squidcloud/client';
import { ParsedAppId, parseAppId } from '@squidcloud/internal-common/types/communication.types';

@Pipe({
  name: 'parseAppId',
  standalone: false,
})
export class ParseAppIdPipe implements PipeTransform {
  transform(value: AppId): ParsedAppId {
    return parseAppId(value);
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { assertTruthy } from 'assertic';
import { IconType } from '@squidcloud/console-web/app/global/services/theme.service';

export interface SidebarMenuItem {
  id: string;
  text: string;
  link?: string;
  iconName: IconType;
  clickFn?: () => void;
}

@Component({
  selector: 'sidebar-menu-item',
  templateUrl: './sidebar-menu-item.component.html',
  styleUrls: ['./sidebar-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SidebarMenuItemComponent implements OnInit, OnChanges {
  @Input()
  item!: SidebarMenuItem;

  @Input()
  isActive!: boolean;

  @Input()
  isMenuExpanded!: boolean;

  isExternalLink = false;

  ngOnInit(): void {
    assertTruthy(this.item);
    assertTruthy(this.isActive !== undefined);
    assertTruthy(this.isMenuExpanded !== undefined);
    this.checkIfExternalLink();
  }

  ngOnChanges(): void {
    assertTruthy(this.item);
    assertTruthy(this.isActive !== undefined);
    assertTruthy(this.isMenuExpanded !== undefined);
    this.checkIfExternalLink();
  }

  onMenuItemClicked(): void {
    if (this.item.clickFn) {
      this.item.clickFn();
    }
  }

  private checkIfExternalLink(): void {
    if (!this.item.link) return;
    this.isExternalLink = !!this.item.link.match(/^https?:\/\/.*/);
  }
}

@let integration = integrationObs | async;
@if (!integration) {
  <loading-indicator></loading-indicator>
} @else {
  <div class="schema_page">
    <div class="container">
      @switch (getSchemaType(integration.type)) {
        @case ('graphql') {
          <graphql-schema
            class="schema_contents"
            (headerTemplateChange)="handleHeaderTemplateChange($event)" />
        }
        @case ('api') {
          <api-schema
            [isNewSchema]="isNewSchema"
            class="schema_contents"
            (headerTemplateChange)="handleHeaderTemplateChange($event)" />
        }
        @case ('ai') {
          <ai-chatbot-profiles
            class="schema_contents"
            (headerTemplateChange)="handleHeaderTemplateChange($event)" />
        }
        @case ('data') {
          <app-data-schema
            [isNewSchema]="isNewSchema"
            class="schema_contents"
            (headerTemplateChange)="handleHeaderTemplateChange($event)" />
        }
        @default {
          Unsupported schema type: <b>{{ integration.type }}</b>
        }
      }
    </div>
  </div>
}

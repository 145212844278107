import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AiChatbotProfilesService } from '@squidcloud/console-web/app/integrations/schema/ai-chatbot-profiles/ai-chatbot-profiles.service';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import { AI_AGENTS_INTEGRATION_ID } from '@squidcloud/client';
import { firstValueFrom } from 'rxjs';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { OnboardingService } from '@squidcloud/console-web/app/onboarding/onboarding.service';
import { getMessageFromError } from 'assertic';
import { AiChatbotIntegrationConfig } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { QuotedActionDialogComponent } from '@squidcloud/console-web/app/global/components/quoted-action-dialog/quoted-action-dialog.component';
import { QuotaService } from '@squidcloud/console-web/app/organization/quota-service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@squidcloud/console-web/app/utils/navigation.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { ONBOARDING_PAGE_PATH } from '@squidcloud/console-web/app/onboarding/onboarding.types';
import { Router } from '@angular/router';

const ONBOARDING_AGENT_NAME = 'car-insurance-ai-agent';
const ONBOARDING_AI_INSTRUCTION_TEXT = `You are a helpful financial assistant who helps answer users' questions concisely and clearly about car insurance. Extract images where useful and possible. Politely decline to answer irrelevant questions.`;

@Component({
  templateUrl: './onboarding-widget-getting-started.component.html',
  styleUrls: ['./onboarding-widget-getting-started.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OnboardingWidgetGettingStartedComponent {
  requestInProgress = false;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly aiChatbotProfilesService: AiChatbotProfilesService,
    private readonly integrationService: IntegrationService,
    private readonly navigationService: NavigationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly snackBarService: SnackBarService,
    private readonly quotaService: QuotaService,
    private readonly dialog: MatDialog,
    onboardingService: OnboardingService,
    router: Router,
  ) {
    if (!onboardingService.onboardingOrganizationId) {
      void router.navigate([ONBOARDING_PAGE_PATH]);
    }
  }

  async createIntegration(): Promise<void> {
    if (!(await this.quotaService.checkCanAddIntegration())) {
      QuotedActionDialogComponent.show(this.dialog, { quotaName: 'maxNumberOfIntegrations' });
      return;
    }
    this.requestInProgress = true;
    this.changeDetectorRef.markForCheck();

    try {
      const navGuard = this.navigationService.newNavigationGuard();
      await this.integrationService.upsertIntegration({
        type: 'ai_agents',
        id: AI_AGENTS_INTEGRATION_ID,
        config: {
          type: 'ai_agents',
          id: AI_AGENTS_INTEGRATION_ID,
        },
      });

      const integration = await firstValueFrom(this.integrationService.observeIntegration(AI_AGENTS_INTEGRATION_ID));
      await this.aiChatbotProfilesService.initializeAiSchema(integration as AiChatbotIntegrationConfig);
      await this.aiChatbotProfilesService.addAgent(ONBOARDING_AGENT_NAME, {
        modelName: 'gpt-4o',
        isPublic: true,
        strictContext: false,
      });
      await this.aiChatbotProfilesService.addInstruction(ONBOARDING_AGENT_NAME, ONBOARDING_AI_INSTRUCTION_TEXT);
      await this.aiChatbotProfilesService.addContext(ONBOARDING_AGENT_NAME, 'Example Allstate car insurance policy', {
        type: 'url',
        data: 'https://doi.nv.gov/uploadedFiles/doinvgov/_public-documents/Consumers/AU127-1.pdf',
      });
      await this.aiChatbotProfilesService.addContext(ONBOARDING_AGENT_NAME, 'Marketwatch car insurance cost factors', {
        type: 'url',
        data: 'https://www.marketwatch.com/guides/insurance-services/factors-that-affect-car-insurance-rates/',
      });
      const app = this.applicationService.getCurrentApplicationOrFail();
      await navGuard.navigateByUrl(
        `/application/${app.appId}/integration/${AI_AGENTS_INTEGRATION_ID}/profiles?tip=onboarding`,
      );
    } catch (error) {
      console.error('Unable to create profile', error);
      this.snackBarService.warning(`Unable to create profile: ${getMessageFromError(error)}`);
    } finally {
      this.requestInProgress = false;
      this.changeDetectorRef.markForCheck();
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { Observable } from 'rxjs';
[];
import { AiChatbotProfile } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import {
  AbilityConnectOptionsMap,
  KNOWLEDGE_ABILITIES,
} from '@squidcloud/console-web/app/studio/agent/abilities/factory';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';

type ConnectEvent = {
  [K in keyof AbilityConnectOptionsMap]: { type: K; options: AbilityConnectOptionsMap[K] };
}[keyof AbilityConnectOptionsMap];

@Component({
  selector: 'studio-flyout',
  templateUrl: './flyout.component.html',
  styleUrl: './flyout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudioFlyoutComponent implements OnChanges {
  readonly list = [{ name: 'Knowledge', abilities: KNOWLEDGE_ABILITIES }];
  readonly expandedObs = this.studioService.observeFlyout();

  @Output() connect = new EventEmitter<ConnectEvent>();

  agent$: Observable<AiChatbotProfile | undefined>;
  @Input({ required: true }) agentId!: string;
  @Input({ required: true }) eligibleAiFunctions!: Array<AiFunctionMetadata>;
  @Input({ required: true }) eligibleConnectedAgentIds!: Array<string>;

  constructor(private readonly studioService: StudioService) {
    this.agent$ = this.studioService.observeAgent(this.agentId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['agentId']) {
      this.agent$ = this.studioService.observeAgent(this.agentId);
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BackendService } from '../backend.service';
import { BackendMetricPeriodType } from '@squidcloud/console-common/types/metrics.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  newErrorsColumn,
  newLinkColumn,
  newMedianColumn,
  newP95Column,
  newSuccessColumn,
  newTextColumnWithTooltip,
} from '@squidcloud/console-web/app/backend/backend-component-utils';

@Component({
  templateUrl: './webhooks.component.html',
  styleUrls: ['./webhooks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WebhooksComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;
  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(private readonly backendService: BackendService) {
    this.backendService.reportBackendTabHasMetrics(false);
    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['webhook'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions
            .sort((m1, m2) => {
              const extras1 = m1.functionData.extras;
              const extras2 = m2.functionData.extras;
              const link1 = extras1['url'] as string;
              const link2 = extras2['url'] as string;
              return link1.localeCompare(link2);
            })
            .map(metric => {
              const { counts, functionData } = metric;
              const { extras } = functionData;
              const link = extras['url'] as string;
              const linkPathStartIdx = link.indexOf('/webhooks/');
              const webhookPath = link.substring(linkPathStartIdx);
              const columns: Array<BundleDataTableColumn> = [
                newLinkColumn(link, webhookPath),
                newSuccessColumn(counts),
                newErrorsColumn(counts),
                newMedianColumn(counts),
                newP95Column(counts),
                newTextColumnWithTooltip(functionData.serviceFunctionName),
              ];
              return { columns };
            });

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;
          return {
            headerRow: [
              {
                name: 'Webhook URL',
                getCopyValue: (column): string => column.link || column.value,
                copyButtonText: 'Copy the full webhook URL',
              },
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Function',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );
  }
}

import { inject, NgZone, Provider, SecurityContext } from '@angular/core';
import { Squid } from '@squidcloud/client';
import { environment } from '@squidcloud/console-web/environments/environment';
import { firstValueFrom } from 'rxjs';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha-2';
import { MarkdownService, SECURITY_CONTEXT } from 'ngx-markdown';
import { AuthService as Auth0Service, provideAuth0 } from '@auth0/auth0-angular';
import { SEGMENT_CONFIG, SegmentService } from 'ngx-segment-analytics';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';

export function provideSquid(): Provider {
  return {
    provide: Squid,
    deps: [NgZone, Auth0Service],
    useFactory: function (ngZone: NgZone, auth0Service: Auth0Service): Squid {
      return new Squid({
        appId: environment.squidAppId,
        messageNotificationWrapper: ngZone.run.bind(ngZone),
        region: environment.squidRegion,
        environmentId: environment.squidEnvironmentId,
        squidDeveloperId: environment.squidDeveloperId,
        authProvider: {
          integrationId: 'auth0',
          getToken: async (): Promise<string | undefined> => {
            // Wait until the authentication state is resolved by Auth0.
            const user = await firstValueFrom(auth0Service.user$);
            if (!user) {
              return undefined;
            }
            try {
              // Try to query an access token without user interaction (silently).
              // This method should never fail.
              return await firstValueFrom(auth0Service.getAccessTokenSilently());
            } catch (e) {
              // If 'getAccessTokenSilently' is failed, it is either a network or a misconfiguration
              // (configuration change) issue.
              // In this case, log out the user: this way the user will be forced to use a UI login
              // form.
              if ((e as Error)?.message?.includes('Login required')) {
                console.warn('Cached Auth0 token is expired. Forcing a new sign in.');
              } else {
                console.error(`Can't get Auth token, logging out.`, e);
              }
              auth0Service.logout({ logoutParams: { returnTo: window.location.origin } });
              return undefined;
            }
          },
        },
      });
    },
  };
}

export function provideRecaptcha(): Provider {
  return {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: environment.recaptchaSiteKey,
    } as RecaptchaSettings,
  };
}

export function provideMarkdown(): Array<Provider> {
  return [MarkdownService, { provide: SECURITY_CONTEXT, useValue: SecurityContext.HTML }];
}

export function provideAuth0ForSquid(): Provider {
  return provideAuth0({
    domain: environment.auth0.customDomain,
    clientId: environment.auth0.clientId,

    // The 'localstorage' will keep ID Token and Access Tokens (one per audience).
    // This cache will prevent extra HTTP request to Auth0 endpoint on page reload.
    //
    // The ID Token will only be renewed if one of the Access Tokens is renewed.
    //
    // The Access Token will be renewed when is asked by a user
    // (getAccessTokenSilently), and the cached Access Token is expired or is within 60
    // seconds from the expiration time.
    cacheLocation: 'localstorage',

    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'console-backend-api-for-web', // Hardcoded: same for all envs.
    },
  });
}

export function provideSegments(): Array<Provider> {
  return [
    SegmentService,
    {
      provide: SEGMENT_CONFIG,
      useValue: {
        apiKey: environment.segmentWriteKey,
        loadOnInitialization: !!environment.segmentWriteKey,
      },
    },
  ];
}

export function setupWindowGlobals(): void {
  (window as unknown as { squid: Squid }).squid = inject(Squid);
  (window as unknown as { squidConsole: Record<string, object> }).squidConsole = {
    organizationService: inject(OrganizationService),
    applicationService: inject(ApplicationService),
    accountService: inject(AccountService),
    environment,
  };
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ServiceFunctionName } from '@squidcloud/client';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';

@Component({
  selector: 'app-ai-chatbot-select-function-dialog',
  templateUrl: './ai-chatbot-select-function-dialog.component.html',
  styleUrl: './ai-chatbot-select-function-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AiChatbotSelectFunctionDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) protected readonly data: AiChatbotSelectFunctionDialogData,
    protected readonly dialogRef: MatDialogRef<AiChatbotSelectFunctionDialogComponent, string | undefined>,
  ) {}

  protected selectedFunction: AiFunctionMetadata | undefined;

  onSelectedFunctionChanged(serviceFunctionName: ServiceFunctionName): void {
    this.selectedFunction = this.data.functions.find(f => f.serviceFunction === serviceFunctionName);
  }

  static show(
    dialog: MatDialog,
    data: AiChatbotSelectFunctionDialogData,
  ): MatDialogRef<AiChatbotSelectFunctionDialogComponent, ServiceFunctionName> {
    return dialog.open<AiChatbotSelectFunctionDialogComponent, AiChatbotSelectFunctionDialogData, ServiceFunctionName>(
      AiChatbotSelectFunctionDialogComponent,
      {
        minWidth: 500,
        maxWidth: 800,
        autoFocus: false,
        restoreFocus: false,
        panelClass: 'modal',
        data,
      },
    );
  }

  onAddButtonClicked(): void {
    if (this.selectedFunction) {
      this.dialogRef.close(this.selectedFunction.serviceFunction);
    } else {
      this.dialogRef.close(undefined);
    }
  }

  getFunctionName(serviceFunction: ServiceFunctionName): string {
    return getFunctionNameFromServiceFunctionName(serviceFunction);
  }
}

interface AiChatbotSelectFunctionDialogData {
  functions: Array<AiFunctionMetadata>;
}

@let currentApplication = currentApplicationObs | async;
@let integrations = integrationsObs | async;
@let isAdmin = ('ADMIN' | minRole);
@if (currentApplication && integrations) {
  <div class="current_integrations_page">
    <div class="current_and_popular_integrations">
      <div class="left">
        <div class="current_integrations">
          @for (integration of integrations; track integration.id) {
            @let isBuiltInIntegration = isBuiltInIntegrationType(integration.type);
            @let showSchemaIsEmpty = showSchemaEmpty(integration);
            <div
              [ngClass]="{ built_in_integration: isBuiltInIntegration }"
              class="integration_card">
              <div class="top_part">
                <div class="left_metadata">
                  <img [src]="'/assets/integrations/' + integration.type + '.svg'"
                       class="integration_icon logo" />
                  <div class="id_and_state_container">
                    <integration-id class="id" [integrationId]="integration.id"></integration-id>
                    <div
                      [ngClass]="{built_in_integration: isBuiltInIntegration, empty_schema: showSchemaIsEmpty}"
                      class="state">
                      {{ isBuiltInIntegration ? 'DEFAULT' : showSchemaIsEmpty ? 'EMPTY SCHEMA' : 'ACTIVE' }}
                    </div>
                  </div>
                </div>
                <div class="right_metadata">
                  @if (integration.type !== 'built_in_queue' && integration.type !== 'built_in_s3' && integration.type !== 'built_in_gcs') {
                    <button [matMenuTriggerData]="{element: integration}" [matMenuTriggerFor]="integrationMenu"
                            class="secondary_icon_button"
                            mat-flat-button
                            [attr.data-testid]="integration.id + '-integration-menu-dots-button'"
                    >
                      <mat-icon class="menu_icon" svgIcon="menu_dots_icon"></mat-icon>
                    </button>
                  }
                </div>
              </div>
              <div class="bottom_part">
                <div class="created_date">Created: {{ integration.creationDate | date: 'medium' }}</div>
                <div class="updated_date">Updated: {{ integration.updateDate | date: 'medium' }}</div>
              </div>
            </div>
          }
        </div>
      </div>
      <div class="right">
        <div class="popular_integrations">
          <div class="popular_integrations_title">Popular Integrations</div>
          @for (integration of popularIntegrations; track integration.name) {
            <div class="popular_integration">
              <div class="logo_and_name">
                <img [src]="'/assets/integrations/' + integration.type + '.svg'" class="integration_icon logo" />
                <div class="name">{{ integration.name }}</div>
              </div>
              <div class="add_integration_button"
                   [matTooltip]="!isAdmin ? 'You need to be an admin to add integrations' : ''">
                <button
                  [routerLink]="['/application', currentApplication.appId, 'integrations', 'add', integration.type]"
                  [disabled]="!isAdmin"
                  (click)="handleAddIntegration(integration.type)"
                  class="plus secondary_icon_button medium_button"
                  [attr.data-testid]="'popular-integration-' + integration.type + '-plus-button'"
                  mat-flat-button>
                  <mat-icon class="plus" svgIcon="plus_icon" />
                </button>
                <button
                  [routerLink]="['/application', currentApplication.appId, 'integrations', 'add', integration.type]"
                  [disabled]="!isAdmin"
                  (click)="handleAddIntegration(integration.type)"
                  class="add secondary_button medium_button" mat-flat-button>
                  Add
                </button>
              </div>
            </div>
          }
          <button
            [routerLink]="['/application', currentApplication.appId, 'integrations', 'available']"
            class="more_integrations_button primary_button large_button" mat-flat-button
            data-testid="more-intergrations-button"
          >
            More Integrations
          </button>
        </div>
      </div>
    </div>

    <mat-menu #integrationMenu="matMenu"
              class="integration_menu"
              xPosition="before">
      <ng-template let-element="element" matMenuContent [typeGuard]="{element: CpIntegration}">
        @let tabs = getTabs(element);
        @if (tabs.includes('details')) {
          <button [routerLink]="['/application', currentApplication.appId, 'integration', element.id, 'edit']"
                  class="menu_item" mat-menu-item
                  data-testid="edit-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="edit_icon"></mat-icon>
            Edit Integration
          </button>
        }
        @if (tabs.includes('schema') || tabs.includes('readonly-schema')) {
          <button [routerLink]="['/application', currentApplication.appId, 'integration',element.id, 'schema']"
                  class="menu_item" mat-menu-item
                  data-testid="schema-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="table_icon"></mat-icon>
            Schema
          </button>
        }
        @if (tabs.includes('db-browser')) {
          <button [routerLink]="['/application', currentApplication.appId, 'integration', element.id, 'db-browser']"
                  class="menu_item" mat-menu-item
                  data-testid="browse-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="browser_collections_icon"></mat-icon>
            Browse
          </button>
        }
        @if (tabs.includes('profiles')) {
          <button [routerLink]="['/application', currentApplication.appId, 'integration', element.id, 'profiles']"
                  class="menu_item" mat-menu-item
                  data-testid="profiles-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="table_icon"></mat-icon>
            Agents
          </button>
        }
        @if (tabs.includes('show-api-script')) {
          <button class="menu_item" mat-menu-item
                  data-testid="show-api-script-menu-button"
                  (click)="showApiScriptDialog(element.id)"
          >
            <mat-icon class="icon" svgIcon="table_icon"></mat-icon>
            Show Creation Script
          </button>
        }
        @if (element.type !== 'built_in_db' && isAdmin) {
          @if (element.type !== 's3' && element.type !== 'gcs') {
            <div class="menu_divider"></div>
          }
          <button
            (click)="showDeleteIntegrationDialog(element.id)"
            class="delete_integration menu_item red_menu_item" mat-menu-item
            data-testid="delete-integration-menu-button"
          >
            <mat-icon class="icon" svgIcon="trash_icon"></mat-icon>
            Delete
          </button>
        }
      </ng-template>
    </mat-menu>
  </div>
}

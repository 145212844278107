import { INTEGRATION_TYPES, IntegrationType } from '../public-types/integration.public-types';
import { assertString, assertTruthy, formatError, ValueAssertion } from 'assertic';

/*** Asserts that `value` is a valid integration type and is not a built-in integration type.  */
export const assertIntegrationType: ValueAssertion<IntegrationType> = (
  value: unknown,
  context = undefined,
): asserts value is IntegrationType => {
  assertTruthy(INTEGRATION_TYPES.includes(value as IntegrationType), () =>
    formatError(context, `Not a valid integration type`, value),
  );
};

/*** Asserts that `value` is a valid integration type and is not a built-in integration type.  */
export const assertNotBuiltInIntegrationType: ValueAssertion<IntegrationType> = (
  value: unknown,
  context = undefined,
): asserts value is IntegrationType => {
  assertString(value, context);
  assertTruthy(!['built_in_db', 'built_in_queue', 'built_in_s3'].includes(value as IntegrationType), () =>
    formatError(context, `The value can't be a built-in integration type`, value),
  );
};

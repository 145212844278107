import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoRudderType } from '../utils';

@Component({
  selector: 'action-card',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ActionComponent {
  @Input() title!: string;
  @Input() description!: string;

  @Input() cta!: string;
  @Input() disabled = false;
  @Input() tooltip?: string;

  @Input() rudder?: InfoRudderType;
  @Input() border = true;
  @Input() warning = false;

  @Output() action = new EventEmitter<void>();
}

import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { Injectable } from '@angular/core';

type Options = {
  connectedAgentId: string;
};

@Injectable({
  providedIn: 'root',
})
export class ConnectedAgentAbility extends BaseAbility<Options, Options> {
  type = 'connected_agent';

  override async onConnect(agentId: string, options: Options): Promise<boolean> {
    const { connectedAgentId } = options;

    return new Promise((resolve, reject) => {
      this.globalUiService.showDialogWithForm<{ description: string }>(
        {
          title: `Connect Agent ${connectedAgentId}`,
          textLines: ['Provide specific details about the connected agent.'],
          submitButtonText: 'Connect Agent',
          formElements: [
            {
              type: 'textarea',
              label: 'Description',
              nameInForm: 'description',
              required: true,
              attributes: { autosize: true, minRows: 3, maxRows: 50 },
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            try {
              if (!data) return;
              const description = data.description;

              const agent = this.studioService.getAgentSchemaOrFail(agentId);
              const newConnectedAgents = (agent.connectedAgents || [])?.filter(p => p.agentId !== connectedAgentId);
              newConnectedAgents.push({
                agentId: connectedAgentId,
                description,
              });
              agent.connectedAgents = newConnectedAgents;

              await this.studioService.updateAgent(agentId, agent);
              resolve(true);
            } catch (e) {
              reject(e);
            }
          },
        },
        true,
      );
    });
  }

  onDelete(agentId: string, options: Options): Promise<boolean> {
    const { connectedAgentId } = options;

    return new Promise(async (resolve, reject) => {
      const agent = this.studioService.getAgentSchemaOrFail(agentId);
      agent.connectedAgents = (agent.connectedAgents || []).filter(f => f.agentId !== connectedAgentId);

      try {
        await this.studioService.updateAgent(agentId, agent);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'schema-card-item',
  templateUrl: './schema-card-item.component.html',
  styleUrls: ['./schema-card-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SchemaCardItemComponent {
  @Input() elevated = false;
  @Output() delete = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
}

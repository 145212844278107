import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ONBOARDING_PAGE_PATH, OnboardingFlowId } from '@squidcloud/console-web/app/onboarding/onboarding.types';
import {
  ONBOARDING_CARDS,
  OnboardingCard,
} from '@squidcloud/console-web/app/onboarding/onboarding-cards/onboarding-cards-data';
import { OnboardingService } from '@squidcloud/console-web/app/onboarding/onboarding.service';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';

const StandardCards: Array<OnboardingFlowId> = ['widget', 'starter'];
const ReturnCards: Array<OnboardingFlowId> = ['widget', 'todo', 'starter'];

@Component({
  selector: 'app-onboarding-cards',
  templateUrl: './onboarding-cards.component.html',
  styleUrl: './onboarding-cards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OnboardingCardsComponent {
  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  get cards(): Array<OnboardingCard> {
    const types = this.getTypes();
    return types.map(card => ONBOARDING_CARDS[card]);
  }

  private getTypes(): Array<OnboardingFlowId> {
    switch (this.onboardingService.type) {
      case 'standard':
        return StandardCards;
      case 'return':
        return ReturnCards;
    }
  }

  async onLearnMore(): Promise<void> {
    await this.analyticsService.track('CTA Clicked', { title: 'Learn More', location: ONBOARDING_PAGE_PATH });
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataSchemaService } from '../data-schema.service';
import { DATA_SCHEMA_FIELD_TYPES, DataSchemaFieldType } from '@squidcloud/internal-common/schema/schema.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { IntegrationType, Squid } from '@squidcloud/client';
import { isValidId } from '@squidcloud/internal-common/utils/validation';
import { isNil } from '@squidcloud/internal-common/utils/object';
import { getMessageFromError, truthy } from 'assertic';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';

export interface DataSchemaFieldData {
  collectionName: string;
  /** Field name. */
  name?: string;
  type?: DataSchemaFieldType;
  min?: number;
  max?: number;
  primaryKey?: boolean;
  defaultValue?: string;
  required?: boolean;
  description?: string;
  integrationType: IntegrationType;
  hidden?: boolean;
}

@Component({
  templateUrl: './data-schema-field-dialog.component.html',
  styleUrls: ['./data-schema-field-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DataSchemaFieldDialogComponent implements OnInit {
  readonly form: FormGroup;
  errorMessage?: string;
  readonly SUPPORTED_FIELD_TYPES_ARRAY = DATA_SCHEMA_FIELD_TYPES;
  readonly isEdit: boolean;
  protected isAiDescriptionInProgress = false;
  hidden = false;
  integrationType: IntegrationType;

  constructor(
    private readonly dialogRef: MatDialogRef<unknown>,
    private readonly formBuilder: FormBuilder,
    private readonly organizationService: OrganizationService,
    private readonly dataSchemaService: DataSchemaService,
    private readonly applicationService: ApplicationService,
    @Inject(MAT_DIALOG_DATA) readonly data: DataSchemaFieldData,
    private readonly squid: Squid,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: SnackBarService,
  ) {
    this.isEdit = !!this.data.name;
    this.form = this.formBuilder.group({
      name: new FormControl(data?.name || '', [Validators.required, this.nameValidator()]),
      type: new FormControl(data?.type || 'string', [Validators.required]),
      min: new FormControl(data?.min, []),
      max: new FormControl(data?.max, []),
      defaultToggled: new FormControl(!isNil(data?.defaultValue), []),
      defaultValue: new FormControl(data?.defaultValue, [this.createDefaultValueValidator()]),
      primaryKey: new FormControl(data?.primaryKey || false, [Validators.required]),
      required: new FormControl(data?.required || false, [Validators.required]),
      description: new FormControl(data.description, []),
    });
    this.integrationType = data.integrationType;
    this.hidden = data?.hidden || false;

    this.organizationService
      .observeRoleInCurrentOrg()
      .pipe(takeUntilDestroyed())
      .subscribe(role => {
        if (role === 'ADMIN') {
          this.form.enable();
        } else {
          this.form.disable();
        }
      });
  }

  ngOnInit(): void {
    if (this.form.get('primaryKey')?.value) {
      this.form.get('defaultToggled')?.disable();
    }

    if (this.hidden) {
      this.form.get('primaryKey')?.disable();
    }

    truthy(this.form.get('type')).valueChanges.subscribe(() => {
      this.form.updateValueAndValidity();
      truthy(this.form.get('defaultValue')).updateValueAndValidity();
    });

    truthy(this.form.get('defaultToggled')).valueChanges.subscribe(() => {
      this.form.updateValueAndValidity();
      truthy(this.form.get('defaultValue')).updateValueAndValidity();
    });

    truthy(this.form.get('primaryKey')).valueChanges.subscribe(value => {
      value ? this.form.get('defaultToggled')?.disable() : this.form.get('defaultToggled')?.enable();
    });
  }

  submitFieldData(): void {
    if (!this.form.valid) return;
    const details = this.form.value;

    if (!this.isEdit) {
      const schema = this.dataSchemaService.getSchemaOrFail();
      const collection = schema.collections[this.data.collectionName] || {};
      const properties = Object.keys(collection.properties || {});
      if (properties.find(fieldName => fieldName.toLowerCase().trim() === details.name.toLowerCase().trim())) {
        this.errorMessage = 'Field already exists, choose a different name';
        return;
      }
    }

    this.dataSchemaService.setField(
      this.data.collectionName,
      this.data.name,
      details.name === null ? undefined : details.name,
      details.type === null ? undefined : details.type,
      details.min === null ? undefined : details.min,
      details.max === null ? undefined : details.max,
      details.defaultValue === null || !details.defaultToggled ? undefined : details.defaultValue,
      details.primaryKey === null ? undefined : details.primaryKey,
      details.required === null ? undefined : details.required,
      details.description === null ? undefined : details.description,
    );

    this.dialogRef.close();
  }

  private createDefaultValueValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const formGroup = control.parent as FormGroup;
      if (isNil(control.value)) {
        if (formGroup?.get('defaultToggled')?.value) {
          return { invalidDefaultValue: 'Default must be set.' };
        }
        return null;
      }
      if (formGroup?.value?.type === 'integer') {
        return !Number.isInteger(Number(control.value)) ? { invalidDefaultValue: 'Invalid Integer Value' } : null;
      }
      if (formGroup?.value?.type === 'number') {
        return isNaN(Number(control.value)) ? { invalidDefaultValue: 'Invalid Number Value' } : null;
      }
      return null;
    };
  }

  primaryKeyChanged(pk: boolean): void {
    if (pk) {
      this.form.get('defaultToggled')?.setValue(false);
      this.form.get('defaultValue')?.setValue(null);
    }
  }

  private nameValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (this.integrationType !== 'built_in_db') return null;

      return isValidId(control.value)
        ? null
        : { invalidSecretKey: 'Only letters, numbers, dashes and underscores allowed.' };
    };
  }

  async onAddDescriptionWithAiClicked(): Promise<void> {
    const fieldName = truthy(this.data.name, `Field name must be defined in edit mode: ${this.data.name}`);
    try {
      this.isAiDescriptionInProgress = true;
      const result = await callBackendExecutable(this.squid, 'discoverDataConnectionSchema', {
        appId: this.applicationService.getCurrentApplicationOrFail().appId,
        integrationConfig: this.dataSchemaService.getCurrentBaseIntegrationConfigForDiscovery(),
        collections: [this.data.collectionName],
        useAi: true,
      });
      const collection = result.schema.collections[this.data.collectionName];
      if (!collection) {
        this.snackBar.warning(`Collection not found: ${this.data.collectionName}`);
        return;
      }
      const property = collection.properties?.[fieldName];
      if (!property) {
        this.snackBar.warning(`Field not found: ${fieldName}`);
        return;
      }
      const description = property.description;
      if (!description) {
        this.snackBar.warning(`AI could not generate a description for the field`);
        return;
      }
      this.form.controls['description'].setValue(description);
    } catch (error) {
      console.error(error);
      this.snackBar.warning(`Internal error: ${getMessageFromError(error)}`);
    } finally {
      this.isAiDescriptionInProgress = false;
      this.cdr.markForCheck();
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { AccountService } from '../../account/account.service';
import { AppId, EnvironmentId } from '@squidcloud/client';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { getBackendInitStageParameter } from '@squidcloud/console-web/app/utils/squid-utils';

@Component({
  selector: 'app-deploy-backend-doc',
  templateUrl: './deploy-backend-doc.component.html',
  styleUrls: ['./deploy-backend-doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DeployBackendDocComponent {
  readonly apiKeySubject = new BehaviorSubject<string | undefined>(undefined);
  readonly appObs = this.applicationService.observeCurrentApplication().pipe(filter(Boolean));
  readonly userObs = this.accountService.observeUser().pipe(filter(Boolean));

  readonly stageParameter = getBackendInitStageParameter();

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly accountService: AccountService,
  ) {
    this.appObs.pipe(take(1), takeUntilDestroyed()).subscribe(async () => {
      const apiKey = await this.applicationService.getApiKey();
      this.apiKeySubject.next(apiKey || '<Your API key here>');
    });
  }

  getEnvironmentId(appId: AppId): EnvironmentId {
    return parseAppId(appId).environmentId;
  }
}

import { AiAgentReference, AiChatbotClient } from './ai-agent-client';
import { AiChatbotClientFactory } from './ai-chatbot-client.factory';
import { RpcManager } from './rpc.manager';
import { AiAssistantClient } from './ai-assistant-client';
import { AI_AGENTS_INTEGRATION_ID, IntegrationId } from './public-types';
import { AiImageClient } from './ai-image-client';
import { AiAudioClient } from './ai-audio-client';
import { AiMatchMakingClient } from './ai-matchmaking-client';
import {
  ExecuteAiApiRequest,
  ExecuteAiApiResponse,
  ExecuteAiQueryMultiRequest,
  ExecuteAiQueryMultiResponse,
  ExecuteAiQueryOptions,
  ExecuteAiQueryRequest,
  ExecuteAiQueryResponse,
} from './ai.types';

/**
 * AiClient class serves as a facade for interacting with different AI services.
 * It provides simplified access to AI chatbot and assistant functionalities
 * through its methods.
 */
export class AiClient {
  private readonly aiAssistantClient = new AiAssistantClient(this.rpcManager);

  /** @internal */
  constructor(
    private readonly aiChatbotClientFactory: AiChatbotClientFactory,
    private readonly rpcManager: RpcManager,
  ) {}

  /**
   * Retrieves an AI chatbot client for a specific AI integration.
   * @param aiIntegrationId - The identifier for the AI integration.
   * @returns An instance of AiChatbotClient associated with the given AI integration ID.
   */
  chatbot(aiIntegrationId: IntegrationId): AiChatbotClient {
    return this.aiChatbotClientFactory.getAgent(aiIntegrationId);
  }

  agent(agentId: string): AiAgentReference {
    return this.aiChatbotClientFactory.getAgent(AI_AGENTS_INTEGRATION_ID).profile(agentId);
  }

  /**
   * Retrieves the AI assistant client.
   * @returns An instance of AiAssistantClient.
   */
  assistant(): AiAssistantClient {
    return this.aiAssistantClient;
  }

  /**
   * Retrieves an AI image client.
   */
  image(): AiImageClient {
    return new AiImageClient(this.rpcManager);
  }

  /**
   * Retrieves an AI audio client.
   */
  audio(): AiAudioClient {
    return new AiAudioClient(this.rpcManager);
  }

  /**
   * Retrieves an AI match-making client.
   */
  matchMaking(): AiMatchMakingClient {
    return new AiMatchMakingClient(this.rpcManager);
  }

  /**
   * Executes an AI query using a specific DB integration, sending a prompt to the AI and returning its response.
   * This function allows for direct interaction with the AI's capabilities by sending text prompts and receiving
   * the AI's responses, which can be used for various applications such as automating tasks, generating content,
   * or obtaining information.
   *
   * @param integrationId The identifier for the DB integration which is used to direct the query to the
   *                      appropriate DB.
   * @param prompt        The text prompt to send to the AI. This should be formulated in a way that the AI can
   *                      understand and respond to, taking into account the nature of the task or the information
   *                      sought.
   * @param options       Additional options to customize the query execution.
   * @returns             A promise that resolves to an `ExecuteAiQueryResponse`. This response includes the AI's
   *                      reply to the provided prompt, along with any other relevant information that is part of
   *                      the AI's response. The promise can be awaited to handle the response asynchronously.
   *
   * @example
   * ```
   * const response = await ai().executeAiQuery(myDbIntegrationId, "How many transactions ran yesterday?");
   * console.log(response);
   * ```
   *
   * For more details on the usage and capabilities of the AI Assistant, refer to the documentation provided at
   * {@link https://docs.squid.cloud/docs/ai}.
   */
  executeAiQuery(
    integrationId: IntegrationId,
    prompt: string,
    options?: ExecuteAiQueryOptions,
  ): Promise<ExecuteAiQueryResponse> {
    const req: ExecuteAiQueryRequest = { integrationId, prompt, options };
    return this.rpcManager.post('aiData/executeAiQuery', req);
  }

  executeAiQueryMulti(
    integrationIds: Array<IntegrationId>,
    prompt: string,
    options?: ExecuteAiQueryOptions,
  ): Promise<ExecuteAiQueryMultiResponse> {
    const req: ExecuteAiQueryMultiRequest = { integrationIds, prompt, options };
    return this.rpcManager.post('aiData/executeAiQueryMulti', req);
  }

  executeAiApiCall(
    integrationId: IntegrationId,
    prompt: string,
    allowedEndpoints?: string[],
    provideExplanation?: boolean,
  ): Promise<ExecuteAiApiResponse> {
    const req: ExecuteAiApiRequest = { integrationId, prompt, allowedEndpoints, provideExplanation };
    return this.rpcManager.post('aiData/executeAiApiCall', req);
  }
}

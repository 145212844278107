import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  TemplateRef,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IntegrationId } from '@squidcloud/client';
import { QuotedActionDialogComponent } from '@squidcloud/console-web/app/global/components/quoted-action-dialog/quoted-action-dialog.component';
import { QuotaService } from '@squidcloud/console-web/app/organization/quota-service';
import { getPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { UpsertIntegrationRequest } from '@squidcloud/internal-common/types/application.types';
import { assertNotBuiltInIntegrationType } from '@squidcloud/internal-common/utils/assertion.utils';
import {
  BaseIntegrationConfig,
  GraphQLIntegrationConfig,
  IntegrationConfig,
  IntegrationTypeWithConfig,
  isGraphQLIntegrationType,
} from '@squidcloud/internal-common/types/integrations/schemas';
import { getInPath, setInPath } from '@squidcloud/internal-common/utils/object';
import { assertTruthy, getMessageFromError, truthy } from 'assertic';
import { filter, firstValueFrom } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { copyText } from '../../utils/copy-utils';
import { IntegrationUiService } from '../integration-ui.service';
import { IntegrationService } from '../integration.service';
import { Integrations, IntegrationTab } from '../utils/content';
import {
  getIntegrationForm,
  IntegrationForm,
  IntegrationFormField,
  IntegrationFormSection,
  isBooleanIntegrationFormField,
  isComponentIntegrationFormField,
  isInputIntegrationFormField,
  isSecretIntegrationFormField,
  isSelectIntegrationFormField,
  ToggleableIntegrationFormSection,
} from './utils/config';
import { kebabCase } from 'change-case';
import { NavigationService } from '@squidcloud/console-web/app/utils/navigation.service';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';

enum TestConnectionState {
  TestConnection,
  Fail,
  Success,
  Retry,
  Trying,
}

@Component({
  selector: 'app-integration-form',
  templateUrl: './integration-form.component.html',
  styleUrls: ['./integration-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IntegrationFormComponent {
  form: FormGroup | undefined;
  integrationType: IntegrationTypeWithConfig | undefined;
  integrationForm: IntegrationForm | undefined;
  isEdit = false;
  toggled = false;
  schemaInitialized: boolean | undefined;
  readonly integrationId: IntegrationId | undefined;
  testConnectionState: TestConnectionState = TestConnectionState.TestConnection;
  formSubmitting = false;
  resetConnectionStateTimer: ReturnType<typeof setTimeout> | null = null;

  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  constructor(
    private readonly formBuilder: FormBuilder,
    { snapshot }: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly organizationService: OrganizationService,
    private readonly applicationService: ApplicationService,
    private readonly snackBar: SnackBarService,
    private readonly integrationService: IntegrationService,
    private readonly integrationUiService: IntegrationUiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly quotaService: QuotaService,
    private readonly dialog: MatDialog,
  ) {
    this.integrationType = snapshot.paramMap.get('type') as IntegrationTypeWithConfig | undefined;
    this.integrationId = getPageParameter(INTEGRATION_ID_PARAMETER, snapshot);
    if (!this.integrationType && !this.integrationId) {
      this.snackBar.warning('Invalid integration type or id');
      return;
    }

    this.isEdit = !!this.integrationId;
    this.setIntegrationForm().then();

    this.organizationService
      .observeRoleInCurrentOrg()
      .pipe(takeUntilDestroyed())
      .subscribe(role => {
        if (role === 'ADMIN') {
          this.form?.enable();
        } else {
          this.form?.disable();
        }
      });
  }

  async submitIntegrationForm(): Promise<void> {
    if (!this.form?.valid) return;
    const upsertRequest = this.convertToUpsertRequest();
    if (!this.isEdit) {
      const allIntegrationIds = Object.keys(this.integrationService.currentApplication.integrations);
      if (allIntegrationIds.find(id => id.trim().toLowerCase() === upsertRequest.id?.trim().toLowerCase())) {
        this.snackBar.warning('Integration with this ID already exists');
        return;
      }
    }

    this.setFormSubmitting(true);

    if (!this.isEdit && !(await this.quotaService.checkCanAddIntegration())) {
      QuotedActionDialogComponent.show(this.dialog, { quotaName: 'maxNumberOfIntegrations' });
      this.setFormSubmitting(false);
      return;
    }

    const navGuard = this.navigationService.newNavigationGuard();
    try {
      await this.testDataConnection();
    } catch (e) {
      this.setFormSubmitting(false);
      console.error('Cannot save integration: Connection failed.');
      this.snackBar.warning(
        `Unable to save integration due to connection failure: ${getMessageFromError(e)}`,
        'Dismiss',
        { duration: 0 },
      );
      return;
    }

    try {
      await this.integrationService.upsertIntegration(upsertRequest);
      const nextStep = this.getNextStep();
      if (this.isEdit || !nextStep || !this.canEditSchema()) {
        this.snackBar.success(`Integration ${this.isEdit ? 'saved' : 'added'}`);
      }

      if (navGuard.hasNotNavigated) {
        if (!this.isEdit) {
          let url: string[];
          if (nextStep) {
            // Adding this intermediate step makes clicking "back" route to the new integration
            await this.integrationUiService.navigate(['edit'], this.form.value.id);
            url = this.canEditSchema() ? [nextStep, 'new'] : [nextStep];
          } else {
            url = ['edit'];
          }
          await this.integrationUiService.navigate(url, this.form.value.id);
        }
      }
    } catch (e) {
      console.error('Unable to submit integration form', e);
      this.snackBar.warning('Unable to save integration, please try again later');
    } finally {
      this.setFormSubmitting(false);
    }
  }

  get isTestConnectionButtonVisible(): boolean {
    return !Integrations[truthy(this.integrationType)].hideTestConnection;
  }

  getTestConnectionButtonText(): string {
    switch (this.testConnectionState) {
      case TestConnectionState.TestConnection:
        return 'Test Connection';
      case TestConnectionState.Fail:
        return 'Error';
      case TestConnectionState.Success:
        return 'Connected';
      case TestConnectionState.Retry:
        return 'Retry Connection';
      default:
        return '';
    }
  }

  isError(): boolean {
    return this.testConnectionState === TestConnectionState.Fail;
  }

  getTestConnectionButtonClasses(): string {
    switch (this.testConnectionState) {
      case TestConnectionState.TestConnection:
      case TestConnectionState.Retry:
      case TestConnectionState.Trying:
        return 'secondary_button large_button';
      case TestConnectionState.Fail:
        return 'warning_button large_button with_icon_on_right';
      case TestConnectionState.Success:
        return 'success_button large_button with_icon_on_right';
      default:
        return '';
    }
  }

  testConnectionIcon(): string {
    switch (this.testConnectionState) {
      case TestConnectionState.Fail:
        return 'warning_circle_icon';
      case TestConnectionState.Success:
        return 'check_circle_filled_icon';
      default:
        return '';
    }
  }

  buttonType(): string {
    switch (this.testConnectionState) {
      case TestConnectionState.TestConnection:
      case TestConnectionState.Retry:
      case TestConnectionState.Trying:
        return 'flat';
      default:
        return 'stroked';
    }
  }

  testConnectionInProgress(): boolean {
    return this.testConnectionState === TestConnectionState.Trying;
  }

  async performDataConnectionTest(): Promise<void> {
    try {
      this.testConnectionState = TestConnectionState.Trying;
      if (this.resetConnectionStateTimer) {
        clearTimeout(this.resetConnectionStateTimer);
      }

      await this.testDataConnection();
      this.testConnectionState = TestConnectionState.Success;
      this.snackBar.success('Connection successful');
    } catch (e) {
      console.error('Unable to test connection', e);
      const errMessage = e instanceof Error ? e.message : 'Unable to test connection, please try again later';

      this.testConnectionState = TestConnectionState.Fail;
      this.snackBar.warning(errMessage, 'Dismiss', { duration: 0 });
    } finally {
      this.resetConnectionStateTimer = setTimeout(() => {
        this.testConnectionState = TestConnectionState.Retry;
        this.cdr.detectChanges();
      }, 10000);
    }
  }

  toggleSection(section: ToggleableIntegrationFormSection): void {
    section.enabled = !section.enabled;

    for (const field of section.fields) {
      if (section.enabled) {
        this.form?.addControl(field.pathInIntegration, this.getFieldFormControl(field));
      } else {
        this.form?.removeControl(field.pathInIntegration);
      }
    }
    if (section.pathInIntegration) {
      if (section.enabled) {
        this.form?.addControl(section.pathInIntegration, new FormControl(true, []));
      } else {
        this.form?.removeControl(section.pathInIntegration);
      }
    }

    this.toggled = true;
  }

  private async setIntegrationForm(): Promise<void> {
    let integration: CpIntegration | undefined = undefined;
    if (this.integrationId) {
      integration = await firstValueFrom(
        this.integrationService.observeIntegration(this.integrationId).pipe(filter(Boolean)),
      );
      this.integrationType = integration.type as IntegrationTypeWithConfig;
    }
    this.schemaInitialized = integration?.schemaInitialized;

    this.integrationForm = getIntegrationForm(truthy(this.integrationType), integration);
    const formControls: Record<string, FormControl> = {};

    for (const section of this.integrationForm.sections || []) {
      if (section.type === 'toggleable' && !section.enabled) {
        for (const field of section.fields) {
          const fieldValue = getInPath(integration, field.pathInIntegration);
          if (fieldValue !== undefined) {
            section.enabled = true;
            break;
          }
        }
      }

      if (section.type === 'toggleable' && !section.enabled) {
        continue;
      }
      for (const field of section.fields) {
        formControls[field.pathInIntegration] = this.getFieldFormControl(field);
      }
    }

    this.form = this.formBuilder.group(formControls);
    this.form?.valueChanges.subscribe(() => {
      if (this.resetConnectionStateTimer) {
        clearTimeout(this.resetConnectionStateTimer);
      }
      this.testConnectionState = TestConnectionState.TestConnection;
    });
    this.cdr.markForCheck();
  }

  private convertToUpsertRequest(): UpsertIntegrationRequest {
    const properties = this.form?.value;
    assertTruthy(this.integrationType !== 'built_in_db');
    assertTruthy(this.integrationType !== 'built_in_queue');
    assertTruthy(this.integrationType !== 'built_in_s3');
    assertTruthy(this.integrationType !== 'built_in_gcs');
    const config = {} as IntegrationConfig;

    for (const [key, value] of Object.entries(properties)) {
      const valueToUse = typeof value === 'string' && !value.trim() ? undefined : value;
      setInPath(config, key, valueToUse);
    }

    // Write toggleable sections state to the config.
    for (const section of this.integrationForm?.sections || []) {
      if (section.type === 'toggleable' && section.pathInIntegration && section.saveToggleStateToIntegrationConfig) {
        setInPath(config, section.pathInIntegration, section.enabled);
      }
    }

    const id = this.integrationId || properties.id;
    const type = truthy(this.integrationType);

    return {
      id,
      type,
      config: {
        ...config,
        id,
        type,
      },
      schemaInitialized: this.schemaInitialized,
    } as UpsertIntegrationRequest;
  }

  private async testDataConnection(): Promise<void> {
    if (!this.form?.valid || Integrations[truthy(this.integrationType)].hideTestConnection) return;
    const request = this.convertToBaseIntegrationConfig();

    try {
      const response = isGraphQLIntegrationType(request.type)
        ? await this.applicationService.testGraphQLConnection(request as GraphQLIntegrationConfig)
        : await this.applicationService.testDataConnection(request);
      if (!response.success) {
        throw Error(response.errorMessage || 'Unable to test connection, please try again later');
      }
    } finally {
      this.cdr.markForCheck();
    }
  }

  private convertToBaseIntegrationConfig(): BaseIntegrationConfig {
    const properties = this.form?.value;
    assertNotBuiltInIntegrationType(this.integrationType);
    const request = {
      type: this.integrationType,
    } as BaseIntegrationConfig;
    for (const [key, value] of Object.entries(properties)) {
      if (typeof value === 'string' && !value.trim()) continue;
      setInPath(request, key, value);
    }
    return request;
  }

  private getFieldFormControl(field: IntegrationFormField): FormControl {
    let defaultValue;
    if (field.defaultValue !== undefined) {
      defaultValue = field.defaultValue;
    } else {
      defaultValue = field.type === 'boolean' ? false : '';
    }
    return new FormControl(defaultValue, [
      ...(field.required ? [Validators.required] : []),
      ...(field.validator ? [this.convertToAngularValidatorFn(field.validator)] : []),
    ]);
  }

  private convertToAngularValidatorFn(fn: (value: unknown) => string | undefined): ValidatorFn {
    return (control: AbstractControl) => {
      const value = control.value;
      const error = fn(value);
      return error ? { error } : null;
    };
  }

  canEditSchema(): boolean {
    if (!this.integrationType) return false;
    const tabs = Integrations[this.integrationType].tabs || [];
    return tabs.includes('schema');
  }

  private getNextStep(): IntegrationTab | undefined {
    if (!this.integrationType) return undefined;
    const tabs = Integrations[this.integrationType].tabs || [];
    const step = tabs[1];
    if (step === 'schema' || step === 'readonly-schema') {
      return 'schema';
    } else if (step === 'profiles') {
      return 'profiles';
    }
    return undefined;
  }

  private setFormSubmitting(val: boolean): void {
    this.formSubmitting = val;
    this.cdr.markForCheck();
  }

  get requiredText(): string {
    return '<required>';
  }

  async copyIntegrationId(): Promise<void> {
    await copyText(truthy(this.integrationId));
    this.snackBar.success('Integration ID copied to clipboard');
  }

  async deleteIntegration(): Promise<void> {
    const result = await this.integrationUiService.showDeleteIntegrationDialog(truthy(this.integrationId));
    if (result) {
      await this.integrationUiService.navigate(['current']);
    }
  }

  getDataTestId(field: { name: string; dataTestId?: string }): string {
    return field.dataTestId || `${kebabCase(field.name)}-field`;
  }

  getSecondaryTitle(integrationForm: IntegrationForm): string | undefined {
    let secondaryTitle = integrationForm.title;
    if (integrationForm.docsUrl) {
      secondaryTitle += ` (<a href="${integrationForm.docsUrl}" target="_blank">docs</a>)`;
    }
    return secondaryTitle;
  }

  isToggleableSection(section: IntegrationFormSection<IntegrationConfig>): section is ToggleableIntegrationFormSection {
    return section.type === 'toggleable';
  }

  protected readonly isComponentIntegrationFormField = isComponentIntegrationFormField;
  protected readonly isInputIntegrationFormField = isInputIntegrationFormField;
  protected readonly isSelectIntegrationFormField = isSelectIntegrationFormField;
  protected readonly isBooleanIntegrationFormField = isBooleanIntegrationFormField;
  protected readonly isSecretIntegrationFormField = isSecretIntegrationFormField;
}

@let isExpanded = expandedObs | async;
@let agent = agent$ | async;

<div class="flyout">
  <div class="outer" [ngClass]="{ expanded: isExpanded }">
    <div class="inner">
      @if (agent) {
        @for (section of list; track section.name) {
          <h3>{{ section.name }}</h3>
          <ul>
            @for (ability of section.abilities; track ability) {
              <li>
                <button (click)="connect.emit({ type: ability, options: undefined })">
                  {{ ability }}
                </button>
              </li>
            }
          </ul>
        }
        <h3>Connected Agents</h3>
        <ul>
          @for (agentId of eligibleConnectedAgentIds; track agentId) {
            <li>
              <button (click)="connect.emit({ type: 'connected_agent', options: { connectedAgentId: agentId } })">
                {{ agentId }}
              </button>
            </li>
          }
        </ul>
        <h3>AI Functions</h3>
        <ul>
          @for (metadata of eligibleAiFunctions; track metadata.serviceFunction) {
            <li>
              <button (click)="connect.emit({ type: 'ai_function', options: { serviceFunction: metadata.serviceFunction } })">
                {{ metadata.serviceFunction }}
              </button>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</div>

import { IntegrationClient } from './integration-client';
import { RpcManager } from './rpc.manager';
import { SecretClient } from './secret.client';
import { SquidRegion } from '../../internal-common/src/public-types/regions.public-types';
import { AppId } from '../../internal-common/src/public-types/communication.public-types';

export class AdminClient {
  private readonly integrationClient = new IntegrationClient(this.rpcManager, this.region, this.appId);
  private readonly secretClient = new SecretClient(this.rpcManager);

  constructor(
    private readonly rpcManager: RpcManager,
    private readonly region: SquidRegion,
    private readonly appId: AppId,
  ) {}

  integrations(): IntegrationClient {
    return this.integrationClient;
  }

  secrets(): SecretClient {
    return this.secretClient;
  }
}

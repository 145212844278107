import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CONTACT_LIST_COLLECTION, CpContact } from '@squidcloud/console-common/types/contact.types';
import { Squid } from '@squidcloud/client';
import { getMessageFromError, truthy } from 'assertic';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { SquidAngularValidators } from '@squidcloud/console-web/app/utils/angular-utils';

interface FormDetails {
  name: FormControl<string>;
  email: FormControl<string>;
  message: FormControl<string>;
}

@Component({
  selector: 'widget-contact',
  templateUrl: './widget-contact.component.html',
  styleUrls: ['./widget-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WidgetContactComponent {
  constructor(
    private readonly squid: Squid,
    private readonly snackBarService: SnackBarService,
  ) {}

  @Input() widgetRef!: HTMLElement;

  form = new FormGroup<FormDetails>({
    name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [SquidAngularValidators.email],
    }),
    message: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  async submitWidgetContact(captchaToken: string): Promise<void> {
    if (!this.form.valid) return;
    const data = this.form.value;
    const name = truthy(data.name, 'INVALID_NAME');
    const email = truthy(data.email, 'INVALID_EMAIL');
    const message = truthy(data.message, 'INVALID_MESSAGE');

    try {
      await this.squid
        .collection<Omit<CpContact, 'submissionDate'>>(CONTACT_LIST_COLLECTION)
        .doc()
        .insert({
          name,
          email,
          message,
          captcha: truthy(captchaToken, 'INVALID_CAPTCHA_TOKEN'),
          reason: 'contact_developers',
          location: 'ai-chat',
        });

      this.snackBarService.success('Thank you for your interest in Squid Cloud!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.widgetRef as any)?.['backToChat']();
    } catch (error) {
      console.error('Unable to submit contact form', error);
      this.snackBarService.warning(
        `We could not process your request at this time. Please try again. Message: ${getMessageFromError(error)}`,
      );
    }
  }

  async captchaResolved(captchaToken: string | null): Promise<void> {
    await this.submitWidgetContact(captchaToken || ''); // Function will show an error for empty captcha.
  }

  captchaError($event: unknown): void {
    console.warn('got captcha error', $event);
    this.snackBarService.warning(
      `We could not process your request at this time. Please try again. Error: ${JSON.stringify($event)}`,
    );
  }
}

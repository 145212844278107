import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoRudderType } from '../utils';

@Component({
  selector: 'info-card',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InfoComponent {
  @Input() title!: string;
  @Input() description!: string;
  @Input() show = true;

  @Input() rudder: InfoRudderType = 'peeking';
  @Input() border = false;
  @Input() warning = false;

  @Output() dismiss = new EventEmitter<void>();

  onDismiss(): void {
    this.show = !this.show;
    this.dismiss.emit();
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { getPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import {
  ONBOARDING_ENTRY_STEP_PARAMETER,
  OnboardingEntryStep,
} from '@squidcloud/console-web/app/onboarding/onboarding.types';
import { ActivatedRoute, Router } from '@angular/router';
import { FormConfig } from '@squidcloud/console-web/app/global/components/magic-form/magic-form.component';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { FormElement, NamePattern } from '@squidcloud/console-web/app/utils/form';
import { USER_DETAILS_ROLES, UserDetailsRole } from '@squidcloud/console-common/types/account.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';

type EntryStepContent = {
  title: string;
  form: FormConfig;
};

const EntrySteps: Array<OnboardingEntryStep> = ['role'];

@Component({
  selector: 'app-onboarding-entry',
  templateUrl: './onboarding-entry.component.html',
  styleUrl: './onboarding-entry.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OnboardingEntryComponent {
  entrySteps?: Array<OnboardingEntryStep>;
  stepContent?: EntryStepContent;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    activatedRoute.params.pipe(takeUntilDestroyed()).subscribe(() => {
      const entryStep =
        getPageParameter<OnboardingEntryStep>(ONBOARDING_ENTRY_STEP_PARAMETER, activatedRoute.snapshot) ||
        EntrySteps[0];
      this.stepContent = this.getStepContent(entryStep);
      this.entrySteps = [entryStep];
      this.cdr.markForCheck();
    });
  }

  getStepContent(step: OnboardingEntryStep): EntryStepContent {
    switch (step) {
      case 'name':
        const form: FormConfig = {
          formElements: [
            {
              type: 'input',
              required: true,
              nameInForm: 'name',
              label: 'First and Last Name',
              defaultValue: this.accountService.getUserOrFail().name,
              extraValidators: [Validators.pattern(NamePattern)],
            },
          ],
          submitButtonText: 'Next',
          onSubmit: async ({ name }): Promise<void> => {
            await this.accountService.updateName((name as string).trim());
            await this.navigateToNextStep('name');
          },
        };
        return {
          title: "What's your name?",
          form,
        };
      case 'role':
        const role = this.accountService.getUserOrFail().details?.role;
        const defaultSelect = role
          ? USER_DETAILS_ROLES.includes(role as UserDetailsRole)
            ? role
            : 'other'
          : undefined;
        const defaultInput = defaultSelect === 'other' ? role : undefined;
        return {
          title: 'What describes you best?',
          form: {
            formElements: [
              {
                type: 'select',
                required: true,
                nameInForm: 'role',
                label: 'Select an answer...',
                options: [
                  { name: 'Full-stack developer', value: 'fullstack-developer' },
                  { name: 'Backend developer', value: 'backend-developer' },
                  { name: 'Frontend developer', value: 'frontend-developer' },
                  { name: 'Product manager', value: 'product-manager' },
                  { name: 'Engineering manager', value: 'engineering-manager' },
                  { name: 'Sales engineer', value: 'sales-engineer' },
                  { name: 'AI/ML data engineer/scientist', value: 'ai-scientist' },
                  { name: 'Other (please describe)', value: 'other' },
                ],
                defaultValue: defaultSelect,
              },
              {
                type: 'input',
                required: true,
                hidden: !defaultInput,
                nameInForm: 'other',
                label: 'Other',
                onChange: (formElement, data): FormElement => {
                  formElement.hidden = data.role !== 'other';
                  return formElement;
                },
                defaultValue: defaultInput,
              },
            ],
            submitButtonText: 'Next',
            onSubmit: async ({ role, other }): Promise<void> => {
              const updateTitle = role === 'other' ? other : role;
              await this.accountService.updateUser({ details: { role: (updateTitle as string).trim() } });
              await this.navigateToNextStep('role');
            },
          },
        };
    }
  }

  private async navigateToNextStep(entryStep: OnboardingEntryStep): Promise<void> {
    const step = EntrySteps.findIndex(s => s === entryStep);
    const nextStep = EntrySteps[step + 1];
    if (nextStep) {
      await this.router.navigate(['onboarding', 'entry', nextStep]);
    } else {
      await this.router.navigate(['onboarding', 'cards']);
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IntegrationType } from '@squidcloud/client';
import { Observable } from 'rxjs';
import { IntegrationService } from '../integration.service';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import {
  isAiIntegrationType,
  isApiIntegrationType,
  isDataIntegrationType,
  isGraphQLIntegrationType,
} from '@squidcloud/internal-common/types/integrations/schemas';

type SchemaType = 'api' | 'graphql' | 'ai' | 'data';

@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SchemaComponent {
  readonly integrationObs: Observable<CpIntegration>;
  isNewSchema: boolean;

  @Output() headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  constructor(
    { snapshot }: ActivatedRoute,
    private readonly integrationService: IntegrationService,
  ) {
    const integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, snapshot);
    this.isNewSchema = !!snapshot.paramMap.get('new');
    this.integrationObs = this.integrationService.observeIntegration(integrationId);
  }

  getSchemaType(type: IntegrationType): SchemaType | undefined {
    if (isApiIntegrationType(type)) return 'api';
    if (isGraphQLIntegrationType(type)) return 'graphql';
    if (isAiIntegrationType(type)) return 'ai';
    if (isDataIntegrationType(type)) return 'data';
    return;
  }

  handleHeaderTemplateChange(template: TemplateRef<unknown>): void {
    this.headerTemplateChange.emit(template);
  }
}

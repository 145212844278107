import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';
import { getApplicationUrl } from '@squidcloud/internal-common/utils/http';
import { BackendMetricPeriodType } from '@squidcloud/console-common/types/metrics.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  newErrorsColumn,
  newLinkColumn,
  newMedianColumn,
  newP95Column,
  newSuccessColumn,
  newTextColumn,
} from '@squidcloud/console-web/app/backend/backend-component-utils';

@Component({
  templateUrl: './openapi.component.html',
  styleUrls: ['./openapi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OpenApiComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;
  protected readonly application$ = this.applicationService.observeCurrentApplication();
  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly backendService: BackendService,
  ) {
    this.backendService.reportBackendTabHasMetrics(false);

    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['openapi'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions
            .sort((m1, m2) => {
              const extras1 = m1.functionData.extras;
              const extras2 = m2.functionData.extras;
              const url1 = extras1['url'] as string;
              const url2 = extras2['url'] as string;
              let result = url1.localeCompare(url2);
              if (result === 0) {
                const httpMethod1 = extras1['method'] as string;
                const httpMethod2 = extras2['method'] as string;
                result = httpMethod1.localeCompare(httpMethod2);
              }
              return result;
            })
            .map(metric => {
              const { counts, functionData } = metric;
              const { extras } = functionData;
              const httpMethod = (extras['method'] as string).toUpperCase();
              const url = extras['url'] as string;
              const urlPathStartIdx = url.indexOf('/openapi/');
              const openapiPath = url.substring(urlPathStartIdx);
              const columns: Array<BundleDataTableColumn> = [
                newLinkColumn(url, openapiPath),
                newTextColumn(httpMethod),
                newSuccessColumn(counts),
                newErrorsColumn(counts),
                newMedianColumn(counts),
                newP95Column(counts),
                newTextColumn(functionData.serviceFunctionName),
              ];
              return { columns };
            });

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;

          return {
            headerRow: [
              'URL',
              'Method',
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Function',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );
  }

  getOpenApiBaseUrl(application: CpApplication): string {
    const regionPrefix = convertToSquidRegion(
      application.cloudId,
      application.region,
      application.shard,
      environment.stage,
    );
    return getApplicationUrl(regionPrefix, application.appId, 'openapi');
  }
}

import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { Injectable } from '@angular/core';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';
import { FunctionName, ServiceFunctionName } from '@squidcloud/client';

type ConnectionOptions = {
  serviceFunction: ServiceFunctionName;
};

type DeleteOptions = {
  fn: FunctionName;
};

@Injectable({
  providedIn: 'root',
})
export class AiFunctionAbility extends BaseAbility<ConnectionOptions, DeleteOptions> {
  type = 'ai_function';

  override async onConnect(agentId: string, options: ConnectionOptions): Promise<boolean> {
    const { serviceFunction } = options;

    return new Promise(async (resolve, reject) => {
      const functionName = getFunctionNameFromServiceFunctionName(serviceFunction);
      const agent = this.studioService.getAgentSchemaOrFail(agentId);
      const currentFunctions = (agent.functions || [])?.filter(f => f !== functionName);
      currentFunctions.push(functionName);
      agent.functions = currentFunctions;

      try {
        await this.studioService.updateAgent(agentId, agent);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  override async onDelete(agentId: string, options: DeleteOptions): Promise<boolean> {
    const { fn } = options;

    return new Promise(async (resolve, reject) => {
      const agent = this.studioService.getAgentSchemaOrFail(agentId);
      agent.functions = (agent.functions || []).filter(f => f !== fn);

      try {
        await this.studioService.updateAgent(agentId, agent);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
}

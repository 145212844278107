import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'illustration',
  templateUrl: './illustration.component.html',
  styleUrls: ['./illustration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IllustrationComponent {
  @Input() icon?: string;
  @Input() image?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() withBackground = false;
  @Input() themed = false;

  constructor(readonly themeService: ThemeService) {}
}

<div class="studio-home">
  <div class="header">
    <h1 class="title">Squid Agent Studio</h1>
    <button mat-flat-button color="primary" (click)="createAgent()">Create AI Agent</button>
  </div>

  <knowledge-card>
    <ng-container content>
      <h1>Creating AI Agents in Three Steps...</h1>
    </ng-container>
  </knowledge-card>

  @let schema = agents$ | async;
  @let application = application$ | async;

  <div class="agents">
    @if (application) {
      @if (schema) {
        @if (hasAgents) {
          @for (agentId of getSortedKeys(schema.profiles); track agentId) {
            <a class="agent" [routerLink]="['/application', application.appId, 'studio', agentId]">
              {{ agentId }}
            </a>
          }
        }
      }
    }
  </div>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { AGENT_ID_PARAMETER, getRequiredPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { AiChatbotProfile } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudioTestComponent {
  readonly agentId: string;
  readonly agent$: Observable<AiChatbotProfile | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agent$ = this.studioService.observeAgent(this.agentId);

    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(() => {
      void this.studioService.initialize();
    });
  }
}

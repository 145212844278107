import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactUsDialogComponent } from '@squidcloud/console-web/app/global/components/contact-us-dialog/contact-us-dialog.component';

interface BillingPlansDialogData {
  isOnStandardPlan: boolean;
}

interface BillingPlanData {
  name: string;
  description: string;
  highlights: string[];
  contactUsButton: boolean;
  standard: boolean;
  expanded: boolean;
}

@Component({
  templateUrl: './billing-plans-dialog.component.html',
  styleUrls: ['./billing-plans-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BillingPlansDialogComponent {
  readonly billingPlansData: Array<BillingPlanData> = [
    {
      name: 'Free',
      expanded: false,
      description: 'Your first application is free to build and run.',
      highlights: [
        'One (1) free Squid application with local, dev, and prod environments',
        'Full access to Squid platform functionality',
        'Full access to Squid Console, all SDKs, and all Squid AI features',
        'Standard resource quotas and limits',
        'Community support',
      ],
      contactUsButton: false,
      standard: true,
    },
    {
      name: 'Enterprise',
      expanded: true,
      description: 'For complex use cases or scaled production.',
      highlights: [
        'Everything in Free',
        'Unlimited Squid applications with local, dev, and prod environments',
        'Custom integrations, queries, Squid AI features and data types',
        'SOC2 certified, regional, with full audit logging and enterprise-level SLAs',
        'Increased resource quotas and limits',
        'Dedicated customer engineering and enterprise support',
        'SSO access',
      ],
      contactUsButton: true,
      standard: false,
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BillingPlansDialogData,
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<unknown>,
  ) {}

  static show(dialog: MatDialog, data: BillingPlansDialogData): MatDialogRef<unknown> {
    return dialog.open(BillingPlansDialogComponent, {
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data,
    });
  }

  showContactUsDialog(): void {
    this.dialogRef.close();
    ContactUsDialogComponent.show(this.dialog, { reason: 'billing_or_quota' });
  }
}

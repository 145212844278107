import { assertString, assertTruthy, formatError, ValueAssertion } from 'assertic';
import {
  ORGANIZATION_ROLES,
  OrganizationId,
  OrganizationRole,
} from '@squidcloud/console-common/types/organization.types';
import { isIntegrationId, isOrganizationId } from '@squidcloud/console-common/utils/console-validators';
import { AppId, IntegrationId } from '@squidcloud/client';
import { MARKETPLACE_TYPES, MarketplaceType } from '@squidcloud/console-common/types/marketplace.types';

/*** Asserts that *value* is a valid organization ID.  */
export const assertOrganizationId: ValueAssertion<string> = (
  value: unknown,
  context = undefined,
): asserts value is OrganizationId => {
  assertTruthy(isOrganizationId(value), () => formatError(context, 'Invalid organization id', value));
};

/*** Asserts that *value* is a valid application ID.  */
export const assertApplicationId: ValueAssertion<string> = (
  value: unknown,
  context = undefined,
): asserts value is AppId => {
  // TODO: improve.
  assertTruthy(typeof value === 'string' && value.length > 1, () =>
    formatError(context, 'Invalid application id', value),
  );
};

/*** Asserts that `value` is a valid integration ID.  */
export const assertIntegrationId: ValueAssertion<IntegrationId> = (
  value: unknown,
  context = undefined,
): asserts value is IntegrationId => {
  assertTruthy(isIntegrationId(value), () => formatError(context, 'Invalid integration id', value));
};

// TODO: improve.
export const assertBillingPlanTemplateId = assertString;

export const assertEventRegistrantData = (data: object): asserts data is object => {
  assertTruthy('name' in data && 'email' in data, () => formatError(undefined, 'Invalid event registrant data', data));
};

export const assertMarketplaceType: ValueAssertion<MarketplaceType> = (
  value: unknown,
  context = undefined,
): asserts value is AppId => {
  assertTruthy(MARKETPLACE_TYPES.includes(value as MarketplaceType), () =>
    formatError(context, 'Invalid marketplace type', value),
  );
};

/*** Asserts that *value* is a valid organization ID.  */
export const assertOrganizationRole: ValueAssertion<OrganizationRole> = (
  value: unknown,
  context = undefined,
): asserts value is OrganizationRole => {
  assertTruthy(ORGANIZATION_ROLES.includes(value as OrganizationRole), () =>
    formatError(context, 'Invalid organization role', value),
  );
};

import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[labelWithPrefix]',
  standalone: false,
})
export class LabelWithPrefixDirective {
  constructor(private _elemRef: ElementRef) {
    /**
     * This is a hack to solve the issue that occurs when there's a mat-icon as a matPrefix for an input
     * This is an angular bug, see bug at:
     * https://github.com/angular/components/blob/main/src/material/form-field/form-field.ts line 671:
     *
     *    // Update the translateX of the floating label to account for the prefix container,
     *     // but allow the CSS to override this setting via a CSS variable when the label is
     *     // floating.
     *     floatingLabel.style.transform = `var(
     *         --mat-mdc-form-field-label-transform,
     *         ${FLOATING_LABEL_DEFAULT_DOCKED_TRANSFORM} translateX(${labelHorizontalOffset})
     *     )`;
     * */
    setTimeout(() => {
      _elemRef.nativeElement.parentNode.style.transform = '';
    }, 0);
  }
}

import { AppId, IntegrationId, IntegrationInfo, IntegrationType, SquidRegion } from './public-types';
import { RpcManager } from './rpc.manager';
import { getConsoleBaseUrl } from './console-utils';

export class IntegrationClient {
  private readonly iacBaseUrl: string;

  /** @internal */
  constructor(
    private readonly rpcManager: RpcManager,
    region: SquidRegion,
    appId: AppId,
  ) {
    this.iacBaseUrl = getConsoleBaseUrl(region, `openapi/iac/applications/${appId}/integrations`);
  }

  async list<ConfigurationType = Record<string, any>>(
    type?: IntegrationType,
  ): Promise<Array<IntegrationInfo<ConfigurationType>>> {
    const integrations = await this.rpcManager.get<IntegrationInfo[]>(this.iacBaseUrl);
    const result = type ? integrations.filter(integration => integration.type === type) : integrations;
    return result as Array<IntegrationInfo<ConfigurationType>>;
  }

  async get<ConfigurationType = Record<string, any>>(
    integrationId: IntegrationId,
  ): Promise<IntegrationInfo<ConfigurationType> | undefined> {
    return await this.rpcManager.get<IntegrationInfo<ConfigurationType>>(`${this.iacBaseUrl}/${integrationId}`);
  }

  async delete(integrationId: IntegrationId): Promise<void> {
    await this.rpcManager.delete(`${this.iacBaseUrl}/${integrationId}`);
  }

  async deleteMany(integrationIds: Array<IntegrationId>): Promise<void> {
    await Promise.all(integrationIds.map(integrationId => this.delete(integrationId)));
  }

  async upsertIntegration<ConfigurationType = Record<string, any>>(
    integration: IntegrationInfo<ConfigurationType>,
  ): Promise<void> {
    await this.rpcManager.put<IntegrationInfo[]>(`${this.iacBaseUrl}/${integration.id}`, integration);
  }
}

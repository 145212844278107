import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AiChatbotProfile } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import {
  AGENT_ID_PARAMETER,
  getPageParameter,
  getRequiredPageParameter,
  TAB_ID_PARAMETER,
} from '@squidcloud/console-web/app/utils/http-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StudioCreateComponent } from '@squidcloud/console-web/app/studio/agent/create/create.component';
import { StudioTestComponent } from '@squidcloud/console-web/app/studio/agent/test/test.component';
import { StudioHowToUseComponent } from '@squidcloud/console-web/app/studio/agent/how-to-use/how-to-use.component';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrl: './agent.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudioAgentComponent {
  selectedIndex = 0;
  readonly tabs = [
    {
      id: 'create',
      name: 'Overview',
      component: StudioCreateComponent,
    },
    {
      id: 'test',
      name: 'Test',
      component: StudioTestComponent,
    },
    {
      id: 'how-to-use',
      name: 'How to Use',
      component: StudioHowToUseComponent,
    },
  ];

  private readonly tabIdToIndexMap: Record<string, number> = this.tabs.reduce(
    (acc, tab, index) => {
      acc[tab.id] = index;
      return acc;
    },
    {} as Record<string, number>,
  );

  private readonly indexToTabIdMap: Record<number, string> = Object.entries(this.tabIdToIndexMap).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {} as Record<number, string>,
  );

  readonly agentId: string;
  readonly agent$: Observable<AiChatbotProfile | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agent$ = this.studioService.observeAgent(this.agentId);

    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(() => {
      void this.studioService.initialize();
    });

    activatedRoute.params.pipe(takeUntilDestroyed()).subscribe(() => {
      const tabId = getPageParameter(TAB_ID_PARAMETER, activatedRoute.snapshot) || this.indexToTabIdMap[0];
      this.selectedIndex = this.tabIdToIndexMap[tabId];
      this.cdr.markForCheck();
    });
  }

  async changeUrl(index: number | null): Promise<void> {
    if (index === null) index = 0;
    await this.router.navigate([
      '/application',
      this.applicationService.getCurrentApplicationOrFail().appId,
      'studio',
      this.agentId,
      this.indexToTabIdMap[index],
    ]);
  }
}

import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseAbility<TConnectOptions = undefined, TDeleteOptions = undefined> {
  abstract type: string;

  public constructor(
    protected readonly studioService: StudioService,
    protected readonly snackBar: SnackBarService,
    protected readonly globalUiService: GlobalUiService,
  ) {}

  // abstract onCreate(agentId: string): Promise<boolean>;

  // abstract onEdit(agentId: string): Promise<boolean>;

  abstract onConnect(agentId: string, options?: TConnectOptions): Promise<boolean>;

  abstract onDelete(agentId: string, options?: TDeleteOptions): Promise<boolean>;
}

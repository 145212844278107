import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'db-browser-row',
  templateUrl: './db-browser-row.component.html',
  styleUrl: './db-browser-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DbBrowserRowComponent {
  @Input()
  label!: string;

  @Input()
  selected = false;

  @Input()
  expanded = false;

  @Output()
  readonly itemClicked = new EventEmitter<void>();
}

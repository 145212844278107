import { Injectable } from '@angular/core';
import { FileAbility } from '@squidcloud/console-web/app/studio/agent/abilities/file.ability';
import { TextAbility } from '@squidcloud/console-web/app/studio/agent/abilities/text.ability';
import { UrlAbility } from '@squidcloud/console-web/app/studio/agent/abilities/url.ability';
import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { AiFunctionAbility } from '@squidcloud/console-web/app/studio/agent/abilities/ai-function.ability';
import { ConnectedAgentAbility } from '@squidcloud/console-web/app/studio/agent/abilities/connected-agent.ability';

type AbilityMap = {
  file: FileAbility;
  text: TextAbility;
  url: UrlAbility;
  connected_agent: ConnectedAgentAbility;
  ai_function: AiFunctionAbility;
};

export type AbilityType = keyof AbilityMap;
export const KNOWLEDGE_ABILITIES = ['file', 'text', 'url'] as const;
export type KnowledgeAbilityType = (typeof KNOWLEDGE_ABILITIES)[number];

export type AbilityConnectOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<infer TConnectOptions, unknown> ? TConnectOptions : never;
};

export type AbilityDeleteOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<unknown, infer TDeleteOptions> ? TDeleteOptions : never;
};

@Injectable()
export class AbilityFactory {
  private readonly abilities: AbilityMap;

  constructor(
    private readonly file: FileAbility,
    private readonly text: TextAbility,
    private readonly url: UrlAbility,
    private readonly connected_agent: ConnectedAgentAbility,
    private readonly ai_function: AiFunctionAbility,
  ) {
    this.abilities = {
      file,
      text,
      url,
      connected_agent,
      ai_function,
    };
  }

  getAbility<T extends AbilityType>(type: T): AbilityMap[T] {
    return this.abilities[type];
  }
}

import { AuthToken } from '../public-types/context.public-types';
import { ClientId, ClientRequestId, IntegrationId, SquidDocId } from '../public-types/communication.public-types';
import { MutationType } from '../public-types/mutation.public-types';
import { CollectionName, DocTimestamp, SquidDocument } from '../public-types/document.public-types';
import { MILLIS_PER_SECOND } from './time-units';

/** @internal */
export type MessageId = string;

/** @internal */
export type MessageFromClientType =
  | 'acknowledge'
  | 'catchup'
  | 'kill'
  | 'acquireLock'
  | 'releaseLock'
  | 'pong'
  | 'getClientInfo';

/** @internal */
interface BaseMessageFromClient {
  type: MessageFromClientType;
  authToken?: AuthToken;
  payload?: unknown;
}

/** @internal */
export interface AcknowledgeMessage extends BaseMessageFromClient {
  type: 'acknowledge';
  payload: Array<MessageId>;
}

/** @internal */
export interface GetClientInfoMessage extends BaseMessageFromClient {
  type: 'getClientInfo';
}

/** @internal */
export interface CatchupMessage extends BaseMessageFromClient {
  type: 'catchup';
}

/** @internal */
export interface KillMessage extends BaseMessageFromClient {
  type: 'kill';
}

/** @internal */
export interface AcquireLockMessage extends BaseMessageFromClient {
  type: 'acquireLock';
  payload: {
    mutex: string;
    timeoutMillis?: number;
    clientRequestId: ClientRequestId;
  };
}

/** @internal */
export interface ReleaseLockMessage extends BaseMessageFromClient {
  type: 'releaseLock';
  payload: {
    lockId: string;
    clientRequestId: ClientRequestId;
  };
}

/** @internal */
export type MessageFromClient =
  | AcknowledgeMessage
  | CatchupMessage
  | KillMessage
  | AcquireLockMessage
  | ReleaseLockMessage
  | GetClientInfoMessage;

/** @internal */
export type MessageToClientType =
  | 'mutations'
  | 'query'
  | 'backendFunction'
  | 'api'
  | 'namedQuery'
  | 'lockAcquired'
  | 'lockReleased'
  | 'aiChatbot'
  | 'ping'
  | 'queue'
  | 'clientInfo';

/** @internal */
interface BaseMessageToClient {
  type: MessageToClientType;
  messageId: MessageId;
  payload: unknown;
}

/** @internal */
export interface BackendFunctionMessageToClient extends BaseMessageToClient {
  type: 'backendFunction';
  clientRequestId: ClientRequestId;
  payload: string;
}

/** @internal */
export interface NamedQueryMessageToClient extends BaseMessageToClient {
  type: 'namedQuery';
  clientRequestId: ClientRequestId;
  payload: string;
}

/** @internal */
export interface LockAcquiredResponseMessageToClient extends BaseMessageToClient {
  type: 'lockAcquired';
  payload: {
    clientRequestId: ClientRequestId;
    lockId: string | undefined; // Undefined when the lock was not acquired
    error?: string;
  };
}

/** @internal */
export interface LockReleasedResponseMessageToClient extends BaseMessageToClient {
  type: 'lockReleased';
  payload: {
    clientRequestId: ClientRequestId;
    lockId: string;
  };
}

/** @internal */
export interface AiChatbotMessageToClient extends BaseMessageToClient {
  type: 'aiChatbot';
  clientRequestId: ClientRequestId;
  payload: {
    token: string;
    complete: boolean;
    tokenIndex?: number;
  };
}

/** @internal */
export interface QueueMessageToClient extends BaseMessageToClient {
  type: 'queue';
  integrationId: IntegrationId;
  topicName: string;
  payload: string[];
}

/** @internal */
export type MessageToClient =
  | MutationsMessageToClient
  | PingMessageToClient
  | QueryResultMessageToClient
  | BackendFunctionMessageToClient
  | NamedQueryMessageToClient
  | LockAcquiredResponseMessageToClient
  | LockReleasedResponseMessageToClient
  | AiChatbotMessageToClient
  | QueueMessageToClient
  | ClientInfoMessageToClient;

/** @internal */
export interface MutationsMessageToClient extends BaseMessageToClient {
  type: 'mutations';
  payload: Array<MutationResultData>;
}

/** @internal */
export interface ClientInfoMessageToClient extends BaseMessageToClient {
  type: 'clientInfo';
  payload: {
    querySubscriptionDump: Array<unknown>;
    tenantHeartbeatResponse: string;
    socketExpiration?: number;
  };
}

/** @internal */
export interface PingMessageToClient extends BaseMessageToClient {
  type: 'ping';
  payload: unknown;
}

/** @internal */
export interface MutationResultData {
  squidDocId: SquidDocId;
  clientRequestId: ClientRequestId;
  mutationType: MutationType;
  mutationTimestamp: DocTimestamp;
  doc: SquidDocument | undefined; // Undefined if the document was deleted
}

/** @internal */
export interface QueryResultData {
  docs: Array<SquidDocument>;
  integrationId: IntegrationId;
  collectionName: CollectionName;
  clientRequestId: ClientRequestId;
}

/** @internal */
export interface QueryErrorData {
  message: string;
  statusCode: number;
}

/** @internal */
export interface BatchQueryResultData {
  results: Record<ClientRequestId, QueryResultData>;
  errors: Record<ClientRequestId, QueryErrorData>;
}

/** @internal */
export interface QueryResultMessageToClient extends BaseMessageToClient {
  type: 'query';
  payload: QueryResultData;
}

/** @internal */
export interface ApiResultData {
  success: boolean;
  httpStatus: number;
  payload: string;
}

/** @internal */
export interface BackendFunctionResultData {
  success: boolean;
  payload: string;
}

/** @internal */
export type PendingMessage = Omit<MessageToClient, 'messageId'>;

/** @internal */
export type PendingMessages = Record<ClientId, Array<PendingMessage>>;

export const SOCKET_RECONNECT_TIMEOUT = 10 * MILLIS_PER_SECOND;

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BundleDataTableColumnHeader, BundleDataTableData, BundleDataTableRow } from './bundle-data.types';
import { LineChart } from '../../../metric/chart/chart.types';
import { formatAsInteger, formatAsNumber, formatAsTimePeriod, trimNumber } from '../../utils/formatting-utils';
import { BackendMetricPeriodType, BackendMetricsChartData } from '@squidcloud/console-common/types/metrics.types';
import { Sort } from '@angular/material/sort';
import { range } from '@squidcloud/internal-common/utils/object';
import { environment } from '@squidcloud/console-web/environments/environment';
import { copy } from '@squidcloud/console-web/app/utils/copy-utils';

@Component({
  selector: 'bundle-data-table',
  templateUrl: './bundle-data-table.component.html',
  styleUrls: ['./bundle-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BundleDataTableComponent implements OnChanges {
  @Input({ required: true })
  bundleDataTableData!: BundleDataTableData;

  /** When provided the component will store its state (like sorted column) in browser's local storage. */
  @Input() stateKey: string | undefined;

  protected selectedPeriodType: BackendMetricPeriodType = 'last-hour';

  @Output() selectedPeriodTypeChange = new EventEmitter<BackendMetricPeriodType>();

  /** Sorted table rows. */
  sortedTableRows: Array<BundleDataTableRow> = [];
  protected sort: Sort = { direction: 'asc', active: '' };

  executionsChart?: LineChart;
  latencyChart?: LineChart;
  qpsChart?: LineChart;

  periods: Array<BundleDataTablePeriodTypeOption> = [
    { type: 'last-hour', name: 'Last Hour' },
    { type: 'last-day', name: 'Last Day' },
    { type: 'last-week', name: 'Last Week' },
    { type: 'last-30-days', name: 'Last 30 Days' },
  ];

  readonly tableRowType: Array<BundleDataTableRow> = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['stateKey']) {
      this.loadStateFromLocalStorage();
    }
    if (this.bundleDataTableData?.metrics) {
      this.executionsChart = this.convertToExecutionsChart(this.bundleDataTableData.metrics);
      this.latencyChart = this.convertToLatencyChart(this.bundleDataTableData.metrics);
      this.qpsChart = this.convertToQpsChart(this.bundleDataTableData.metrics);
    } else {
      this.executionsChart = undefined;
      this.latencyChart = undefined;
      this.qpsChart = undefined;
    }
    this.sortTableRows(this.sort);
  }

  get columns(): Array<BundleDataTableColumnHeader> {
    return this.bundleDataTableData.headerRow.map(v => (typeof v === 'string' ? { name: v } : v));
  }

  get columnNames(): string[] {
    return this.bundleDataTableData.headerRow.map(v => (typeof v === 'string' ? v : v.name));
  }

  getColumnName(index: number): string {
    const column = this.bundleDataTableData.headerRow[index];
    return typeof column === 'object' ? column.name : column;
  }

  sortTableRows(sort: Sort): void {
    this.sort = sort;
    if (!this.sort.active) {
      this.sort.active = this.getColumnName(0);
    }
    if (this.sort.direction === '') {
      // Default sort follows @Input state.
      this.sortedTableRows = this.bundleDataTableData.rows;
    } else {
      const activeColumnIndex = Math.max(this.columnNames.indexOf(sort.active), 0);
      this.sortedTableRows = [...this.bundleDataTableData.rows].sort((rowA, rowB) => {
        const cellA = rowA.columns[activeColumnIndex];
        const cellB = rowB.columns[activeColumnIndex];
        const aValue = cellA?.sortValue ?? cellA.value;
        const bValue = cellB?.sortValue ?? cellB.value;
        const comparatorResult =
          typeof aValue === 'number' && typeof bValue === 'number'
            ? aValue - bValue
            : `${aValue}`.localeCompare(`${bValue}`);
        return sort.direction === 'asc' ? comparatorResult : -comparatorResult;
      });
    }
    this.saveStateToLocalStorage();
  }

  convertToExecutionsChart(metrics: BackendMetricsChartData): LineChart {
    return {
      type: 'line',
      template: 'summary_on_left',
      summaryData: [
        {
          label: 'Executions',
          value: `${formatAsInteger(metrics.totalExecutions)}`,
          color: 'var(--doc4)',
        },
        {
          label: 'Errors',
          value: `${formatAsInteger(metrics.totalErrors)}`,
          color: 'var(--fail1)',
        },
      ],
      options: {
        legend: false,
        showXAndYAxis: true,
        xAxisTicks: this.buildXAxisTicks(metrics),
      },
      data: [
        {
          name: 'Executions',
          series: metrics.successfulExecutionCountsSeries.map((itemValue, i) => {
            const time = Date.parse(metrics.timestampSeries[i]);
            const name = this.getHistogramTimestamp(time);
            return {
              name: name,
              value: trimNumber(itemValue),
              formattedValue: formatAsInteger(itemValue),
            };
          }),
        },
        {
          name: 'Errors',
          series: metrics.errorExecutionCountsSeries.map((itemValue, i) => {
            const name = this.getHistogramTimestamp(Date.parse(metrics.timestampSeries[i]));
            return {
              name: name,
              value: trimNumber(itemValue),
              formattedValue: formatAsInteger(itemValue),
            };
          }),
        },
      ],
    };
  }

  private buildXAxisTicks(metrics: BackendMetricsChartData): string[] {
    return this.getEvenlySpacedItems(
      metrics.timestampSeries.map(timestamp => {
        const time = Date.parse(timestamp);
        return this.getHistogramTimestamp(time);
      }),
      this.selectedPeriodType === 'last-week' ? 5 : 8,
    );
  }

  private getHistogramTimestamp(timestamp: number): string {
    const date = new Date(timestamp);

    switch (this.selectedPeriodType) {
      case 'last-hour':
      case 'last-day':
        // Format as HH:mm.
        return date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      case 'last-week':
        // Format as MMM DD HH:mm
        return date
          .toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Use 24-hour format.
          })
          .replace(',', ''); // Remove comma between date and time.
      case 'last-30-days':
        // Format as MMM DD
        return date.toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
        });
    }
  }

  convertToLatencyChart(metrics: BackendMetricsChartData): LineChart {
    return {
      type: 'line',
      template: 'summary_on_top',
      summaryData: [
        {
          label: 'Avg Latency',
          value: formatAsTimePeriod(metrics.successfulExecutionAverageTime),
          color: 'var(--doc5)',
        },
      ],
      options: {
        legend: false,
        showXAndYAxis: true,
        xAxisTicks: this.buildXAxisTicks(metrics),
      },
      data: [
        {
          name: 'Avg Latency',
          series: metrics.successfulExecutionAverageTimeSeries.map((itemValue, i) => {
            return {
              name: this.getHistogramTimestamp(Date.parse(metrics.timestampSeries[i])),
              value: trimNumber(itemValue),
              formattedValue: formatAsTimePeriod(itemValue),
            };
          }),
        },
      ],
    };
  }

  private getEvenlySpacedItems<T>(arr: T[], maximumNumberOfItems: number): T[] {
    const n = arr.length;
    let step = 1;
    if (n > maximumNumberOfItems) {
      step = Math.floor(n / (maximumNumberOfItems - 1));
    }
    const indices = range(0, n, step, maximumNumberOfItems);
    const result = indices.map(i => arr[i]);
    if (result[result.length - 1] !== arr[arr.length - 1]) {
      result[result.length - 1] = arr[arr.length - 1];
    }
    return result;
  }

  convertToQpsChart(metrics: BackendMetricsChartData): LineChart {
    return {
      type: 'line',
      template: 'summary_on_top',
      summaryData: [
        {
          label: 'Avg QPS',
          value: formatAsNumber(metrics.averageQps),
          color: 'var(--doc2)',
        },
      ],
      options: {
        legend: false,
        showXAndYAxis: true,
        xAxisTicks: this.buildXAxisTicks(metrics),
      },
      data: [
        {
          name: 'Avg QPS',
          series: metrics.averageQpsSeries.map((itemValue, i) => {
            return {
              name: this.getHistogramTimestamp(Date.parse(metrics.timestampSeries[i])),
              value: trimNumber(itemValue),
              formattedValue: formatAsNumber(itemValue),
            };
          }),
        },
      ],
    };
  }

  get hasChartData(): boolean {
    if (environment.stage === 'local' && this.stateKey === 'executables') {
      // Show charts in local env even with no data.
      return true;
    }
    const allCharts = [this.executionsChart, this.latencyChart, this.qpsChart];
    return (
      allCharts.every(chart => chart !== undefined) &&
      allCharts.some(
        (chart: LineChart | undefined): boolean =>
          !!chart?.data?.some(lineChartData => lineChartData.series.some(p => p.value !== 0)),
      )
    );
  }

  private saveStateToLocalStorage(): void {
    if (!this.stateKey) {
      return;
    }
    const state = { sort: this.sort };
    localStorage.setItem(`bundle-data-table:${this.stateKey}`, JSON.stringify(state));
  }

  private loadStateFromLocalStorage(): void {
    if (!this.stateKey) {
      return;
    }
    const stateStringValue = localStorage.getItem(`bundle-data-table:${this.stateKey}`);
    if (!stateStringValue) {
      return;
    }
    try {
      const state = JSON.parse(stateStringValue);
      this.sort = state.sort;
    } catch (error) {
      console.error('Failed to restore table state:', error);
    }
  }

  onSelectedPeriodTypeChanged(): void {
    this.selectedPeriodTypeChange.emit(this.selectedPeriodType);
  }

  readonly copy = copy;
}

interface BundleDataTablePeriodTypeOption {
  type: BackendMetricPeriodType;
  name: string;
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { assertTruthy } from 'assertic';

export interface MiniBundleDataTableRow {
  columns: Array<string>;
  link?: string;
}

export interface MiniBundleDataTableData {
  title: string;
  iconName: string;
  headerRow: Array<string>;
  rows: Array<MiniBundleDataTableRow>;
}

@Component({
  selector: 'mini-bundle-data-table',
  templateUrl: './mini-bundle-data-table.component.html',
  styleUrls: ['./mini-bundle-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MiniBundleDataTableComponent implements OnInit, OnChanges {
  @Input()
  data!: MiniBundleDataTableData;

  ngOnChanges(): void {
    assertTruthy(this.data);
  }

  ngOnInit(): void {
    assertTruthy(this.data);
  }
}

import { Injectable } from '@angular/core';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { SegmentService } from 'ngx-segment-analytics';
import { take } from 'rxjs';

type BaseProperties = {
  appId?: string;
  orgId?: string;
  traits?: {
    name?: string;
    email?: string;
  };
};

type Traits = {
  userId?: string;
  email?: string;
  name?: string;
  squidDeveloperId?: string;
};

const ALLOWED_HOSTS = ['console.getsquid.ai'];
const EXCLUDED_EMAIL_PREFIXES = ['joey+synthetic-tests-signup'];

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private baseProperties: BaseProperties = {};
  private traits: Traits = {};

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly organizationService: OrganizationService,
    private readonly accountService: AccountService,
    private readonly segment: SegmentService,
  ) {
    segment.addSourceMiddleware(function ({ payload, next }) {
      const pageUrl = payload.obj?.context?.page?.url;
      if (pageUrl) {
        const url = new URL(pageUrl);
        if (!ALLOWED_HOSTS.includes(url.hostname)) {
          return;
        }
      }
      const userEmail = payload.obj?.context.traits?.email;
      for (const prefix of EXCLUDED_EMAIL_PREFIXES) {
        if (userEmail.startsWith(prefix)) {
          return;
        }
      }
      next(payload);
    });

    applicationService.observeCurrentApplication().subscribe(application => {
      this.baseProperties.appId = application?.appId;
    });
    organizationService.observeCurrentOrganization().subscribe(organization => {
      this.baseProperties.orgId = organization?.id;
    });
    this.accountService.observeUser().subscribe(user => {
      if (!user) return;
      const { id, email, name, squidDeveloperId } = user;
      this.traits = { userId: id, email, name, squidDeveloperId };
      void this.segment.identify(squidDeveloperId, this.traits);
    });

    this.accountService
      .observeUser()
      .pipe(take(1))
      .subscribe(() => {
        void this.page();
      });
  }

  page(): Promise<SegmentService> {
    return this.segment.page('Page Viewed', { title: document.title }, { context: { traits: this.traits } });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(event: string, properties?: Record<string, any>, options?: Record<string, any>): Promise<SegmentService> {
    return this.segment.track(
      event,
      { ...this.baseProperties, ...properties },
      { context: { traits: this.traits }, ...options },
    );
  }
}

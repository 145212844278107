import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './onboarding-welcome.component.html',
  styleUrls: ['./onboarding-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OnboardingWelcomeComponent implements OnInit, OnDestroy {
  private timer: number | undefined;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.timer = window.setTimeout(() => {
      void this.router.navigate(['/onboarding', 'entry']);
    }, 1500);
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timer);
  }
}

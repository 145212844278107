import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditLogEvent } from '@squidcloud/internal-common/types/observability.types';

export interface AuditLogEntryDialogData {
  logEntry: AuditLogEvent;
}

@Component({
  templateUrl: './audit-log-entry-dialog.component.html',
  styleUrls: ['./audit-log-entry-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AuditLogEntryDialogComponent {
  protected readonly logEntry: AuditLogEvent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: AuditLogEntryDialogData) {
    this.logEntry = data.logEntry;
  }

  getLogTags(logEntry: AuditLogEvent): string {
    if (!logEntry) {
      return '';
    } else {
      const tags = Object.keys(logEntry.tags).map(key => `${key}: ${logEntry.tags[key]}`);
      return `Tags:\n${tags.join('\n')}`;
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-copy-code',
  templateUrl: './copy-code.component.html',
  styleUrls: ['./copy-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CopyCodeComponent {
  constructor(private readonly snackBar: SnackBarService) {}

  async copyCode(): Promise<void> {
    this.snackBar.success('Code copied');
  }
}

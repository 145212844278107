import { getApplicationUrl } from '../../internal-common/src/utils/http';

export function getConsoleBaseUrl(appRegion: string, path = ''): string {
  if (appRegion.includes('sandbox')) {
    return getApplicationUrl('us-east-1.aws.sandbox', 'console', path);
  }
  switch (appRegion) {
    case 'local':
      return getApplicationUrl('local', 'console', path);
    default:
      return getApplicationUrl('us-east-1.aws', 'console', path);
  }
}

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { AccountService } from '../../account/account.service';
import { AppId, SquidRegion } from '@squidcloud/client';
import { OnboardingService } from '../onboarding.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { appIdWithEnvironmentId } from '@squidcloud/internal-common/types/communication.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';
import { kebabCase } from 'change-case';
import { getPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import {
  ONBOARDING_FLOW_ID_PARAMETER,
  ONBOARDING_FLOW_IDS,
  OnboardingFlowId,
} from '@squidcloud/console-web/app/onboarding/onboarding.types';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-onboarding-getting-started',
  templateUrl: './onboarding-getting-started.component.html',
  styleUrls: ['./onboarding-getting-started.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OnboardingGettingStartedComponent {
  readonly apiKeySubject = new BehaviorSubject<string | undefined>(undefined);
  readonly appObs = this.applicationService.observeCurrentApplication().pipe(filter(Boolean));
  readonly userObs = this.accountService.observeUser().pipe(filter(Boolean));
  readonly flow: OnboardingFlowId;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly accountService: AccountService,
    private readonly onboardingService: OnboardingService,
    private readonly router: Router,
    activatedRoute: ActivatedRoute,
  ) {
    const flowParameter = getPageParameter<OnboardingFlowId>(ONBOARDING_FLOW_ID_PARAMETER, activatedRoute.snapshot);
    this.flow = ONBOARDING_FLOW_IDS.includes(flowParameter as OnboardingFlowId)
      ? (flowParameter as OnboardingFlowId)
      : 'archer-lite';

    this.appObs.pipe(take(1), takeUntilDestroyed()).subscribe(async () => {
      const apiKey = await this.applicationService.getApiKey();
      this.apiKeySubject.next(apiKey || '<Your API key here>');
    });
  }

  getAppName(appName: string): string {
    return kebabCase(appName);
  }

  getRegion(app: CpApplication): SquidRegion {
    return convertToSquidRegion(app.cloudId, app.region, app.shard, environment.stage);
  }

  getProdAppId(appId: AppId): string {
    return appIdWithEnvironmentId(appId, 'prod');
  }

  handleDone(): void {
    this.onboardingService.showDone
      ? this.router.navigate(['/onboarding', 'done'])
      : this.router.navigate(['/organization']);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { type JsonEditorOptions as AngJsonEditorOptions } from 'ang-jsoneditor';

/**
 * A component that loads ang-json-editor lazily (deferred).
 * Currently, uses an old-style lazy loading pattern for Angular.
 *
 * Note: To use it with a '@deferred {}' and remove the boilerplate code we either need to wait until next
 * Angular release or convert Squid Console app to be started as a standalone component itself, instead of NgModule.
 */
@Component({
  selector: 'app-json-editor',
  template: '<ng-container #jsonEditorContainer></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class JsonEditorComponent implements OnInit, OnChanges {
  @Input({ required: true }) options!: JsonEditorOptions;
  @Input({ required: true }) data!: object;

  @ViewChild('jsonEditorContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;

  private componentRef?: ComponentRef<{ options: JsonEditorOptions; data: object }>;

  ngOnInit(): void {
    void this.loadJsonEditor();
  }

  ngOnChanges(): void {
    this.updateInputs();
  }

  private updateInputs(): void {
    if (this.componentRef) {
      this.componentRef.instance.options = this.options;
      this.componentRef.instance.data = this.data;
      this.componentRef.injector.get(ChangeDetectorRef).markForCheck();
    }
  }

  async loadJsonEditor(): Promise<void> {
    const angJsonEditor = await import('ang-jsoneditor');
    this.componentRef = this.container.createComponent(angJsonEditor.JsonEditorComponent);
    this.updateInputs();
  }
}

export type JsonEditorOptions = AngJsonEditorOptions;

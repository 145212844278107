@let isAdmin = 'ADMIN' | minRole;
@let selectedProfileId = selectedProfileId$ | async;
@let hasContext = !!(selectedProfileId && checkHasContext(selectedProfileId));
@let hasInstructions = !!(selectedProfileId && checkHasInstructions(selectedProfileId));
@let hasConnectedAiAgents = !!(selectedProfileId && checkHasConnectedAiAgents(selectedProfileId));
@let hasAiFunctions = !!(selectedProfileId && checkHasAiFunctions(selectedProfileId));

@if (applicationObs | async; as application) {
  @if (integration$ | async; as integration) {
    @let schema = chatbotProfiles$ | async;
    <div class="schema">
      <portal class="flex_shrink_0" [emitter]="headerTemplateChange">
        <integration-header
          [integrationType]="integration.type"
          [title]="isAgent(integration) ? application.name : integration.id"
          [forceSubtitle]="isAgent(integration) ? 'AI Agents' : ''"
        >
        </integration-header>
      </portal>

      @if (schema) {
        <div class="page_content">
          <div class="left">
            <div class="left_tables">
              <div class="left_table">
                <schema-table-header
                  title="Agents"
                  class="mb_16"
                  (add)="showProfileDialog()"
                  [hideAdd]="!hasProfiles"
                />
                @if (hasProfiles) {
                  <div class="left_rows">
                    @for (profileId of getSortedKeys(schema.profiles); track profileId) {
                      @let profile = schema.profiles[profileId];
                      <schema-select-row
                        [title]="profileId"
                        icon="robot_icon"
                        (activate)="selectProfile(profileId)"
                        [selected]="profileId === selectedProfileId"
                        [attr.data-testid]="'ai-profile-row-' + profile"
                      >
                        @if (profile.isPublic) {
                          <div class="outline_badge is_public">Public</div>
                        }
                      </schema-select-row>
                    }
                  </div>
                } @else {
                  <div class="left_button">
                    <button (click)="showProfileDialog()"
                            class="primary_button medium_button"
                            data-testid="add-ai-profile-button"
                            mat-flat-button>
                      Add agent
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>

          <div class="right">
            @if (selectedProfileId) {
              @let profile = schema.profiles[selectedProfileId];
              <div class="ai_header_container">
                <div class="title_and_icon">
                  <mat-icon svgIcon="robot_icon" class="icon"></mat-icon>
                  {{ selectedProfileId }}
                </div>
                <div class="header_right">
                  <div class="model_name">{{ ModelNameMap[profile.modelName] }}</div>
                  <button class="test_chat_button primary_button medium_button" mat-flat-button
                          (click)="toggleTestChat()"
                          data-testid="test-chat-button"
                  >
                    Test chat
                  </button>
                  <button class="test_chat_button secondary_button medium_button" mat-flat-button
                          (click)="showEmbedWidgetDialog(integration)">
                    Embed widget
                  </button>
                  <button (click)="showProfileDialog(selectedProfileId)"
                          class="settings_button secondary_icon_button"
                          mat-flat-button>
                    <mat-icon svgIcon="settings_icon" class="settings_icon" />
                  </button>
                </div>
              </div>

              <!-- Instructions -->
              <info-card
                class="mb_24"
                description="Instructions set the rules for how the agent responds and answers questions. They should be direct and simple, and explain the purpose of the agent."
                rudder="mech"
                [show]="!hasInstructions">
                <schema-card
                  content
                  titleText="Instructions"
                  icon="api_endpoint_icon"
                  [expanded]="hasInstructions"
                  [hideToggle]="!hasInstructions"
                  (add)="showAddInstructionDialog()"
                  data-testid="ai-chatbot-instructions-card"
                >
                  @for (entry of getEntries(profile.instructions); track entry[0]) {
                    <schema-card-item
                      (delete)="showDeleteInstructionDialog(entry[0])"
                      (edit)="showEditInstructionDialog(selectedProfileId, entry[0], entry[1])"
                    >
                      <div class="card_item instruction_item">
                        <div class="title">{{ entry[1] }}</div>
                      </div>
                    </schema-card-item>
                  }
                </schema-card>
              </info-card>

              <!-- Context -->
              <info-card
                class="mb_24"
                description="Context tells the agent what knowledge to pull from when answering questions. Adding context allows the agent to provide relevant answers on specific topics that may not be part of the underlying AI model."
                rudder="mech_peeking"
                [show]="!hasContext"
              >
                <schema-card
                  content
                  titleText="Context"
                  icon="api_endpoint_icon"
                  [expanded]="hasContext"
                  [hideToggle]="!hasContext"
                  (add)="showAddContextDialog()">
                  @for (idAndContext of getEntries(profile.contexts); track idAndContext[0]) {
                    <schema-card-item (delete)="showDeleteContextDialog(idAndContext[0])">
                      <div class="card_item context_item">
                        <div class="title">
                          {{ idAndContext[1].title }}
                          <div class="id">(ID: {{ idAndContext[0] }})</div>
                        </div>
                        <p class="description">{{ idAndContext[1].text }}</p>
                      </div>
                    </schema-card-item>
                  }
                </schema-card>
              </info-card>

              <!-- Connected AI Agents -->
              <info-card
                class="mb_24"
                description="A connected AI agent can be invoked by the primary agent to enhance the quality of responses to user questions. Each connected agent can, in turn, collaborate with other connected agents as needed."
                rudder="peeking"
                [show]="!hasConnectedAiAgents"
              >
                <schema-card
                  content
                  titleText="Connected AI Agents"
                  icon="robot_icon"
                  [expanded]="hasConnectedAiAgents"
                  [hideToggle]="!hasConnectedAiAgents"
                  (add)="showAddConnectedAiAgentDialog(selectedProfileId)">
                  @for (connectedAgent of profile.connectedAgents; track connectedAgent.agentId) {
                    <schema-card-item
                      (delete)="showDeleteConnectedAiAgentDialog(selectedProfileId, connectedAgent.agentId)">
                      <div class="card_item context_item">
                        <div class="title">{{ connectedAgent.agentId }}</div>
                        <div class="description">{{ connectedAgent.description }}</div>
                      </div>
                    </schema-card-item>
                  }
                </schema-card>
              </info-card>

              <!-- AI Functions -->
              <info-card
                class="mb_24"
                description="AI functions define how Squid AI agents should handle specific queries. Adding functions here will make the agent use them by default in every response."
                rudder="peeking"
                [show]="!hasAiFunctions"
              >
                <schema-card
                  content
                  titleText="AI Functions"
                  icon="api_endpoint_icon"
                  [expanded]="hasAiFunctions"
                  [hideToggle]="!hasAiFunctions"
                  (add)="showAddFunctionDialog(selectedProfileId)">
                  @for (functionName of profile.functions; track functionName) {
                    <schema-card-item (delete)="showDeleteAiFunctionDialog(selectedProfileId, functionName)">
                      <div class="card_item context_item">
                        <div class="title">{{ functionName }}</div>
                        <div class="description">{{ getAiFunctionDescription(functionName) }}</div>
                      </div>
                    </schema-card-item>
                  }
                </schema-card>
              </info-card>
            } @else {
              <illustration
                title="Arrrr, I am an empty vessel"
                description="Build your own AI agents with custom instructions and persistent contexts."
                icon="rudder_mechanical"
                [withBackground]="true"
              />
            }
            @if (isAdmin && selectedProfileId) {
              <div class="danger_zone">
                <action-card
                  title="Delete agent"
                  description="Once this profile is deleted there is no going back."
                  (action)="showDeleteProfileDialog(selectedProfileId)"
                  cta="Delete this agent"
                  [warning]="true"
                />
              </div>
            }
          </div>
        </div>
      }
    </div>
  }
}

import { Injectable } from '@angular/core';
import { generateShortId, GraphQLConnectionOptions, Squid } from '@squidcloud/client';
import { ApplicationService } from '../../../application/application.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { IntegrationService } from '../../integration.service';
import { BaseSchemaService } from '../base-schema.service';
import { truthy } from 'assertic';
import { getApplicationUrl } from '@squidcloud/internal-common/utils/http';
import { EmptyIntrospection } from '@squidcloud/internal-common/types/graphql.types';
import { IntegrationGraphQLSchema } from '@squidcloud/internal-common/types/integrations/api.types';
import { environment } from '@squidcloud/console-web/environments/environment';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { GraphQLIntegrationConfig } from '@squidcloud/internal-common/types/integrations/schemas';

@Injectable({
  providedIn: 'root',
})
export class GraphQLSchemaService extends BaseSchemaService<GraphQLIntegrationConfig, IntegrationGraphQLSchema> {
  constructor(
    applicationService: ApplicationService,
    integrationService: IntegrationService,
    snackBar: SnackBarService,
    squid: Squid,
  ) {
    super(applicationService, integrationService, snackBar, squid);
  }

  override getEmptySchema(): IntegrationGraphQLSchema {
    return { type: 'graphql', introspection: EmptyIntrospection };
  }

  override async discoverSchema(): Promise<IntegrationGraphQLSchema> {
    const application = this.applicationService.getCurrentApplicationOrFail();
    const discovery = await callBackendExecutable(this.squid, 'discoverGraphQLConnectionSchema', {
      id: `discoverGraphQLConnectionSchema_${generateShortId()}`,
      type: 'graphql',
      configuration: {
        connectionOptions: truthy(this.integration?.configuration?.connectionOptions) as GraphQLConnectionOptions,
      },
      appId: application.appId,
    });

    this.schemaSubject.next(discovery.schema);
    return discovery.schema;
  }

  getUrl(): string {
    if (!this.integration) return '';
    const application = this.applicationService.getCurrentApplicationOrFail();
    return getApplicationUrl(environment.squidRegion, application.appId, `${this.integration.id}/graphql`);
  }

  async getHeaders(): Promise<Record<string, string>> {
    if (!this.integration) return {};
    const apiKey = await this.applicationService.getApiKey();
    return {
      authorization: `ApiKey ${apiKey}`,
    };
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { FormConfig } from '@squidcloud/console-web/app/global/components/magic-form/magic-form.component';
import { OrganizationContactInfo } from '@squidcloud/console-common/types/organization.types';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { FormElement } from '@squidcloud/console-web/app/utils/form';

@Component({
  selector: 'organization-contact-info',
  templateUrl: './organization-contact-info.component.html',
  styleUrl: './organization-contact-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OrganizationContactInfoComponent {
  formConfig: FormConfig = {
    formElements: [],
    disabled: true,
    submitButtonText: 'Update',
    disableSubmitButtonWhenPristine: true,
    layoutType: 'page',
    hideRequiredTextForValidValues: true,
    onSubmit: res => void this.updateOrganizationContactInfo(res),
  };

  constructor(
    protected readonly organizationService: OrganizationService,
    private readonly snackBar: SnackBarService,
    cdr: ChangeDetectorRef,
  ) {
    this.organizationService.currentOrganization$.pipe(filter(Boolean), takeUntilDestroyed()).subscribe(org => {
      this.formConfig = { ...this.formConfig, formElements: [] }; // Make a new object (reference) after every update.
      cdr.markForCheck();
      if (org === undefined) {
        return;
      }
      this.formConfig.disabled = organizationService.getMyRoleInCurrentOrg() !== 'ADMIN';
      const contactInfo = org.contactInfo || {
        companyName: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
        phoneNumber: '',
      };
      this.formConfig.formElements = getPublicOrganizationContactInfoFormElements(contactInfo);
    });
  }

  async updateOrganizationContactInfo(formFields: Record<string, unknown>): Promise<void> {
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const contactInfo = pickOrganizationContactInfoFromFormFields(formFields);
    try {
      await this.organizationService.updateContactInfo(organization.id, contactInfo, 'by-user');
    } catch (error) {
      console.error('Failed to update contact info', error);
      this.snackBar.warning('Failed to update contact info');
      return;
    }
    this.snackBar.success('Organization contact info updated');
  }
}

export function getPublicOrganizationContactInfoFormElements(contactInfo: OrganizationContactInfo): Array<FormElement> {
  return [
    {
      type: 'input',
      inputType: 'text',
      nameInForm: 'companyName',
      label: 'Company Name',
      required: true,
      floatLabel: 'always',
      defaultValue: contactInfo.companyName,
      description: 'The official name of the organization or business.',
    },
    {
      type: 'input',
      inputType: 'text',
      nameInForm: 'firstName',
      label: 'First Name',
      required: true,
      floatLabel: 'always',
      defaultValue: contactInfo.firstName,
      description: 'The first name of the primary contact person.',
    },
    {
      type: 'input',
      inputType: 'text',
      nameInForm: 'lastName',
      label: 'Last Name',
      required: true,
      floatLabel: 'always',
      defaultValue: contactInfo.lastName,
      description: 'The last name or surname of the primary contact person.',
    },
    {
      type: 'input',
      inputType: 'text',
      nameInForm: 'jobTitle',
      label: 'Job Title',
      required: true,
      floatLabel: 'always',
      defaultValue: contactInfo.jobTitle,
      description: 'The role or position held by the contact person.',
    },
    {
      type: 'input',
      inputType: 'email',
      nameInForm: 'email',
      label: 'Work Email',
      required: true,
      floatLabel: 'always',
      defaultValue: contactInfo.email,
      description: 'The email address used for official communications.',
    },
    {
      type: 'input',
      inputType: 'text',
      nameInForm: 'phoneNumber',
      label: 'Phone Number',
      required: true,
      floatLabel: 'always',
      defaultValue: contactInfo.phoneNumber,
      description: 'The primary contact phone number for reaching the organization or contact person.',
    },
  ];
}

export function pickOrganizationContactInfoFromFormFields(
  formFields: Record<string, unknown>,
): OrganizationContactInfo {
  return {
    companyName: formFields['companyName'] as string,
    firstName: formFields['firstName'] as string,
    lastName: formFields['lastName'] as string,
    jobTitle: formFields['jobTitle'] as string,
    email: formFields['email'] as string,
    phoneNumber: formFields['phoneNumber'] as string,
  };
}

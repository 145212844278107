import { ChangeDetectionStrategy, Component } from '@angular/core';
import { debounceTime, Observable, take } from 'rxjs';
import { AiChatbotProfile } from '@squidcloud/internal-common/types/integrations/ai_chatbot.types';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { AGENT_ID_PARAMETER, getRequiredPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AiAgentId, AiChatModelName, AiProfileId } from '@squidcloud/client';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import {
  AbilityConnectOptionsMap,
  AbilityDeleteOptionsMap,
  AbilityFactory,
  AbilityType,
} from '@squidcloud/console-web/app/studio/agent/abilities/factory';
import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';
import { getEntries } from '@squidcloud/console-web/app/utils/angular-utils';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AbilityFactory],
})
export class StudioCreateComponent {
  readonly agentId: string;
  readonly agent$: Observable<AiChatbotProfile | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  getEntries = getEntries;

  ModelNameMap: Record<AiChatModelName, string> = {
    'gpt-4o': 'GPT-4o',
    'gpt-4o-mini': 'GPT-4o Mini',
    o1: 'GPT-o1',
    'o1-mini': 'GPT-o1 Mini',
    'gemini-1.5-pro': 'Gemini Pro 1.5',
    'gemini-1.5-flash': 'Gemini Flash 1.5',
    'claude-3-5-sonnet-latest': 'Claude 3.5 Sonnet',
    'claude-3-5-haiku-latest': 'Claude 3.5 Haiku',
  };

  readonly modelOptions = Object.entries(this.ModelNameMap).map(([value, name]) => ({ name, value }));

  form!: FormGroup;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly formBuilder: FormBuilder,
    private readonly globalUiService: GlobalUiService,
    private readonly abilityFactory: AbilityFactory,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agent$ = this.studioService.observeAgent(this.agentId);

    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(() => {
      void this.studioService.initialize();
    });

    this.form = this.formBuilder.group({
      model: new FormControl('', []),
      instructions: new FormControl('', []),
    });

    // Set default values for the modelName and instructions.
    this.agent$.pipe(take(1)).subscribe(agent => {
      if (agent) {
        this.form.patchValue({ model: agent.modelName, instructions: Object.values(agent.instructions)[0] || '' });
      }
    });

    this.form.get('model')?.valueChanges.subscribe(this.updateModel.bind(this));
    this.form.get('instructions')?.valueChanges.pipe(debounceTime(500)).subscribe(this.upsertInstructions.bind(this));
  }

  async updateModel(value: AiChatModelName): Promise<void> {
    try {
      await this.studioService.updateAgent(this.agentId, { modelName: value });
    } catch (e) {
      console.error('Error:', e);
    }
  }

  async upsertInstructions(value: string): Promise<void> {
    const { instructions } = this.studioService.getAgentSchemaOrFail(this.agentId);
    const id = Object.keys(instructions)[0];
    try {
      if (id) {
        await this.studioService.updateInstruction(this.agentId, id, value);
      } else {
        await this.studioService.addInstruction(this.agentId, value);
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  addAbility(): void {
    this.studioService.toggleFlyout();
  }

  async onConnect<T extends AbilityType>(type: T, options?: AbilityConnectOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    const success = await ability.onConnect(this.agentId, options);
    console.log(success);
  }

  async onDelete<T extends AbilityType>(type: T, options?: AbilityDeleteOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    const success = await ability.onDelete(this.agentId, options);
    console.log(success);
  }

  private getAbility<T extends AbilityType>(
    type: T,
  ): BaseAbility<AbilityConnectOptionsMap[T], AbilityDeleteOptionsMap[T]> {
    return this.abilityFactory.getAbility(type) as BaseAbility<AbilityConnectOptionsMap[T], AbilityDeleteOptionsMap[T]>;
  }

  getEligibleAiFunctions(): Array<AiFunctionMetadata> {
    const allAiFunctions = Object.values(
      this.applicationService.getCurrentApplicationOrFail().bundleData?.aiFunctions || {},
    );
    const agent = this.studioService.getAgentSchemaOrFail(this.agentId);
    const usedFunctions = agent.functions || [];
    return allAiFunctions.filter(
      f => !usedFunctions.includes(getFunctionNameFromServiceFunctionName(f.serviceFunction)),
    );
  }

  getEligibleConnectedAgentIds(): Array<AiProfileId> {
    const schema = this.studioService.getSchemaOrFail();
    const allOtherAgentIds = Object.keys(schema.profiles).filter(id => id !== this.agentId);
    const agentIdsThatDoNotUseCurrentAgent: Array<AiProfileId> = [];
    const checkedAgentResults = new Map<AiAgentId, boolean>();

    for (const agentId of allOtherAgentIds) {
      const isDependencyLoop = this.checkIfAgentIdIsInConnectedAgentHierarchy(
        this.agentId,
        agentId,
        checkedAgentResults,
        schema.profiles,
      );
      if (!isDependencyLoop) {
        agentIdsThatDoNotUseCurrentAgent.push(agentId);
      }
    }

    const connectedAgents = schema.profiles[this.agentId]?.connectedAgents || [];
    return agentIdsThatDoNotUseCurrentAgent.filter(id => !connectedAgents.some(p => p.agentId === id));
  }

  private checkIfAgentIdIsInConnectedAgentHierarchy(
    agentId: AiAgentId,
    agentIdToCheck: AiAgentId,
    checkedAgentResults: Map<AiAgentId, boolean>,
    allProfiles: Record<AiAgentId, AiChatbotProfile>,
  ): boolean {
    const checkedResult = checkedAgentResults.get(agentIdToCheck);
    if (checkedResult !== undefined) {
      return checkedResult;
    }
    const agentToCheck = allProfiles[agentIdToCheck];
    if (!agentToCheck?.connectedAgents) {
      checkedAgentResults.set(agentIdToCheck, false);
      return false;
    }
    for (const agentInfo of agentToCheck.connectedAgents) {
      if (
        agentInfo.agentId === agentId ||
        this.checkIfAgentIdIsInConnectedAgentHierarchy(agentId, agentInfo.agentId, checkedAgentResults, allProfiles)
      ) {
        checkedAgentResults.set(agentIdToCheck, true);
        return true;
      }
    }
    checkedAgentResults.set(agentIdToCheck, false);
    return false;
  }
}

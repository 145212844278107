import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { typeGuard } from '@squidcloud/console-web/app/global/directives/template-type-guard.directive';

@Component({
  selector: 'schema-field-menu',
  templateUrl: './schema-field-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SchemaFieldMenuComponent {
  @Input() element!: string;
  @Input() hidden!: boolean;
  @Input() disableHide!: boolean;

  @Output() edit = new EventEmitter<string>();
  @Output() duplicate = new EventEmitter<string>();
  @Output() hide = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();

  protected readonly string = typeGuard<string>();
}

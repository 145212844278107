import { BlobAndFilename } from './types';
import { deserializeObj, serializeObj } from '../../internal-common/src/utils/serialization';
import { DebugLogger } from '../../internal-common/src/utils/global.utils';
import { SQUIDCLOUD_CLIENT_PACKAGE_VERSION } from './version';

export class RpcError<BodyType = unknown> extends Error {
  /** @internal */
  constructor(
    readonly statusCode: number,
    readonly statusText: string,
    readonly url: string,
    readonly headers: Record<string, string>,
    readonly body: BodyType,
    message?: string,
  ) {
    super(message || `RPC error ${statusCode} ${statusText} calling ${url}`);
  }
}

/** @internal */
export interface HttpPostInput<RequestBodyType = unknown> {
  url: string;
  headers: Record<string, string>;
  message: RequestBodyType;
  files: Array<File | BlobAndFilename>;
  filesFieldName: string;
  extractErrorMessage: boolean;
}

/**
 * A more general request interface for other HTTP methods
 * (GET, PUT, PATCH, DELETE).
 */
interface HttpRequestInput<RequestBodyType = unknown> {
  url: string;
  headers: Record<string, string>;
  message?: RequestBodyType;
  files?: Array<File | BlobAndFilename>;
  filesFieldName?: string;
  extractErrorMessage: boolean;
  /**
   * Which method to use. E.g. 'GET', 'PUT', 'PATCH', 'DELETE'...
   * For POST, we still use `HttpPostInput` for backward-compat.
   */
  method: string;
}

/** A response object with type T for the body. */
export interface HttpResponse<BodyType = unknown> {
  status: number;
  statusText: string;
  headers: Record<string, string>;
  body: BodyType;
}

// -----------------------------------------------------------------------------
// POST - Keep as is, for backward compatibility
// -----------------------------------------------------------------------------

/**
 * Runs a POST request to the given URL.
 * @internal
 */
export async function rawSquidHttpPost<ResponseType = unknown, RequestType = unknown>(
  input: HttpPostInput<RequestType>,
): Promise<HttpResponse<ResponseType>> {
  const response = await performFetchRequest({
    url: input.url,
    headers: input.headers,
    method: 'POST',
    message: input.message,
    files: input.files,
    filesFieldName: input.filesFieldName,
    extractErrorMessage: input.extractErrorMessage,
  });
  response.body = tryDeserializing<ResponseType>(response.body as string);
  return response as HttpResponse<ResponseType>;
}

// -----------------------------------------------------------------------------
// GET
// -----------------------------------------------------------------------------
export async function rawSquidHttpGet<ResponseType = unknown, RequestType = unknown>(
  input: Omit<HttpRequestInput<RequestType>, 'method' | 'files' | 'filesFieldName'>,
): Promise<HttpResponse<ResponseType>> {
  const response = await performFetchRequest({
    ...input,
    method: 'GET',
    // no files or form-data for GET, so omit
    files: [],
    filesFieldName: '',
  });
  response.body = tryDeserializing<ResponseType>(response.body as string);
  return response as HttpResponse<ResponseType>;
}

// -----------------------------------------------------------------------------
// PUT
// -----------------------------------------------------------------------------
export async function rawSquidHttpPut<ResponseType = unknown, RequestType = unknown>(
  input: Omit<HttpRequestInput<RequestType>, 'method'> & {
    files?: Array<File | BlobAndFilename>;
    filesFieldName?: string;
  },
): Promise<HttpResponse<ResponseType>> {
  const response = await performFetchRequest({
    ...input,
    method: 'PUT',
  });
  response.body = tryDeserializing<ResponseType>(response.body as string);
  return response as HttpResponse<ResponseType>;
}

// -----------------------------------------------------------------------------
// PATCH
// -----------------------------------------------------------------------------
export async function rawSquidHttpPatch<ResponseType = unknown, RequestType = unknown>(
  input: Omit<HttpRequestInput<RequestType>, 'method'> & {
    files?: Array<File | BlobAndFilename>;
    filesFieldName?: string;
  },
): Promise<HttpResponse<ResponseType>> {
  const response = await performFetchRequest({
    ...input,
    method: 'PATCH',
  });
  response.body = tryDeserializing<ResponseType>(response.body as string);
  return response as HttpResponse<ResponseType>;
}

// -----------------------------------------------------------------------------
// DELETE
// -----------------------------------------------------------------------------
export async function rawSquidHttpDelete<ResponseType = unknown, RequestType = unknown>(
  input: Omit<HttpRequestInput<RequestType>, 'method' | 'files' | 'filesFieldName'>,
): Promise<HttpResponse<ResponseType>> {
  const response = await performFetchRequest({
    ...input,
    method: 'DELETE',
    // typically no files for DELETE, so omit
    files: [],
    filesFieldName: '',
  });
  response.body = tryDeserializing<ResponseType>(response.body as string);
  return response as HttpResponse<ResponseType>;
}

// -----------------------------------------------------------------------------
// Internal fetch request
// -----------------------------------------------------------------------------
async function performFetchRequest({
  headers,
  files,
  filesFieldName,
  message,
  url,
  extractErrorMessage,
  method,
}: HttpRequestInput): Promise<HttpResponse> {
  const requestOptionHeaders = new Headers(headers);
  requestOptionHeaders.append('x-squid-client-version', SQUIDCLOUD_CLIENT_PACKAGE_VERSION);

  const requestOptions: RequestInit = {
    method,
    headers: requestOptionHeaders,
    body: undefined,
  };

  // For GET or DELETE, we typically do NOT send a JSON body or files.
  // For POST/PUT/PATCH, handle them as form-data if files exist,
  // otherwise send JSON.
  if (method !== 'GET' && method !== 'DELETE') {
    if (files && files.length) {
      const formData = new FormData();
      for (const file of files) {
        const blob = file instanceof Blob ? file : (file as BlobAndFilename).blob;
        const filename = file instanceof Blob ? file.name : (file as BlobAndFilename).name;
        formData.append(filesFieldName || 'files', blob, filename);
      }
      formData.append('body', serializeObj(message));
      requestOptions.body = formData;
    } else if (typeof message !== 'undefined') {
      requestOptionHeaders.append('Content-Type', 'application/json');
      requestOptions.body = serializeObj(message);
    }
  } else if (method === 'DELETE' && typeof message !== 'undefined') {
    // Some APIs support a JSON body in DELETE requests
    requestOptionHeaders.append('Content-Type', 'application/json');
    requestOptions.body = serializeObj(message);
  }

  try {
    const response = await fetch(url, requestOptions);
    const responseHeaders: Record<string, string> = {};
    response.headers.forEach((value, key) => {
      responseHeaders[key] = value;
    });

    if (!response.ok) {
      const rawBody = await response.text();
      const parsedBody = tryDeserializing<{ message?: string }>(rawBody);

      if (!extractErrorMessage) {
        throw new RpcError(response.status, response.statusText, url, responseHeaders, parsedBody, rawBody);
      }

      let messageToThrow;
      try {
        messageToThrow = typeof parsedBody === 'string' ? parsedBody : parsedBody?.['message'] || rawBody;
      } catch {}
      if (!messageToThrow) messageToThrow = response.statusText;

      throw new RpcError(response.status, response.statusText, url, responseHeaders, parsedBody, messageToThrow);
    }

    const responseBody = await response.text();
    DebugLogger.debug(`received response from url ${url}: ${JSON.stringify(responseBody)}`);
    return {
      body: responseBody,
      headers: responseHeaders,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (e) {
    DebugLogger.debug(`Unable to perform fetch request to url: ${url}`, e);
    throw e;
  }
}

/**
 * @internal
 *
 * Note: This method hides potential error.
 * Every endpoint call should know what kind of result to expect
 * and call 'deserializeObj' directly if needed.
 */
export function tryDeserializing<T>(text: string | undefined): T | string | undefined {
  if (!text) return undefined;
  try {
    return deserializeObj(text);
  } catch {}
  return text;
}

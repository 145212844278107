import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { truthy } from 'assertic';
import { Injectable } from '@angular/core';

type DeleteOptions = {
  contextId: string;
};

@Injectable({
  providedIn: 'root',
})
export class TextAbility extends BaseAbility<undefined, DeleteOptions> {
  type = 'text';

  override async onConnect(agentId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.globalUiService.showDialogWithForm<{ title: string; name: string; text: string }>(
        {
          title: 'Add Context',
          textLines: ['Provide specific additional knowledge the agent should use when answering users.'],
          submitButtonText: 'Add Context',
          formElements: [
            {
              type: 'input',
              required: true,
              nameInForm: 'title',
              label: 'Context title',
            },
            {
              type: 'textarea',
              required: true,
              nameInForm: 'text',
              label: 'Enter context',
              placeholder: 'Provide contextual knowledge here',
              attributes: {
                autosize: true,
                minRows: 12,
                maxRows: 16,
              },
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            if (!data) return;
            const title = data.title;
            const value = data.text;
            try {
              await this.studioService.addContext(agentId, title, {
                type: 'text',
                data: truthy(value, 'NO_CONTEXT_VALUE'),
              });
              this.snackBar.success('Context added');
              resolve(true);
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to add context';
              this.snackBar.warning(message);
              reject(message);
            }
          },
        },
        true,
      );
    });
  }

  override async onDelete(agentId: string, options: DeleteOptions): Promise<boolean> {
    const { contextId } = options;
    try {
      await this.studioService.deleteContext(agentId, contextId);
      return true;
    } catch {
      return false;
    }
  }
}

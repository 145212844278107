import {
  BillingPlanPeriod,
  BillingPlanQuotas,
  BillingPlanRateLimits,
  BillingPlanTemplate,
  BillingPlanTemplateId,
} from './billing.types';
import { assertTruthy } from 'assertic';
import { QuotaName } from '@squidcloud/internal-common/quota.types';
import { IntegrationConfig } from '@squidcloud/internal-common/types/integrations/schemas';
import { MetricName } from '@squidcloud/internal-common/metric-name';

/** Shared quotas for all Free plans. Resets every billing period (1 month). */
const freePlanPeriodQuotas: BillingPlanQuotas = {
  maxAiContextBytes: 10_000_000,
  maxMessageQueueBytes: 50_000_000,
  maxNumberOfConcurrentConnections: 1000,
  numberOfSecretMutations: 1000,
  numberOfSecretQueries: 10_000,
  numberOfSquidActions: 20_000 * 30, // 20k per day.
  numberOfClaude2Chats: 100,
  numberOfGeminiProChats: 100,
  numberOfGpt35Chats: 300,
  numberOfGpt4Chats: 100,
  numberOfMetrics: 100_000,
};

/** Lifetime limits used by the Free plan. Never resets. */
const freePlanLifetimeQuotas: BillingPlanQuotas = {
  /** Count of applications per organization. */
  maxNumberOfApplications: 10,
  /** Count of integrations per application (counts independently for dev & prod modes). */
  maxNumberOfIntegrations: 50,
};

export const NUMBER_OF_GPT_CHATS_METRIC: MetricName = 'squid_aiChatbotChatOutcome_count';

// We do not support handling of different types of the same quota at the same time today:
// renewing, usage-limit notification, etc...
assertTruthy(
  ![...Object.keys(freePlanLifetimeQuotas)].some(key => freePlanPeriodQuotas[key as QuotaName]),
  'aiPlanWithNoPaymentDetailsLifetimeQuotas overlaps with aiPlansPeriodQuotas',
);

/** Rate limits common for all Free plans. */
const freePlansRateLimits: BillingPlanRateLimits = {
  aiChatPerSecond: 100,
  metricQueriesPerSecond: 100,
  metricReportsPerSecond: 10,
  secretMutationsPerSecond: 10,
  secretQueriesPerSecond: 10,
  squidActionsPerSecond: 100,
};

/** Billing period common for all AI plans. */
const freePlansBillingPeriod: BillingPlanPeriod = {
  interval: 'month',
  intervalCount: 1,
};

export function getBillingPlanTemplateById(templateId: BillingPlanTemplateId): BillingPlanTemplate {
  switch (templateId) {
    case 'AI_STANDARD':
    case 'AI_STANDARD_NO_PAYMENT_DETAILS':
      return {
        templateId,
        name: 'Free',
        description: 'Your first application is free to build and run.',
        price: 0,
        quotas: { ...freePlanPeriodQuotas },
        lifetimeQuotas: { ...freePlanLifetimeQuotas },
        rateLimits: { ...freePlansRateLimits },
        billingPeriod: { ...freePlansBillingPeriod },
      };
    case 'DAILY_V1':
      return {
        templateId,
        name: 'Daily V1',
        description: 'Daily V1 billing plan.',
        quotas: { ...freePlanPeriodQuotas },
        rateLimits: { ...freePlansRateLimits },
        price: 0,
        billingPeriod: { interval: 'day', intervalCount: 1 },
      };
    case 'UNLIMITED':
      return {
        templateId,
        name: 'Enterprise',
        description: 'A plan for complex use cases or scaled production.',
        quotas: {},
        rateLimits: {},
        price: 0,
        billingPeriod: { interval: 'month', intervalCount: 1 },
      };
  }
}

export function isIntegrationExcludedFromQuotas(integration: IntegrationConfig): boolean {
  return integration.type === 'built_in_db' || integration.type === 'built_in_queue';
}

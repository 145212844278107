import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Shows loading indicator component over the host component if [isLoading]=true. */
@Component({
  selector: 'loading-indicator-overlay',
  templateUrl: './loading-indicator-overlay.component.html',
  styleUrl: './loading-indicator-overlay.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadingIndicatorOverlayComponent implements OnChanges {
  /**
   * Current loading state.
   * The loading indicator is only shown when isLoading is set to `true`.
   */
  @Input({ required: true }) isLoading = false;
  /**
   * Color of the loading indicator.
   * Since the indicator is usually shown for disabled buttons the default color is 'white' (inactive).
   */
  @Input() color: 'white' | 'blue' = 'white';
  /**
   * The wrapped content will be hidden when the loading indicator is shown (default).
   * If set to 'false' the loading indicator will be shown on top of the original content.
   */
  @Input() hideContentWhenLoading = true;
  /**
   * The loading indicator is shown after `delayMillis` after `isLoading` is set to true.
   * Helps to avoid flickering for actions that complete fast and do not need any indicator.
   */
  @Input() delayMillis = 500;

  protected isInitialDelayWaitFinished = false;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isLoading']) {
      if (this.isLoading) {
        timer(this.delayMillis)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            this.isInitialDelayWaitFinished = this.isLoading;
            this.cdr.markForCheck();
          });
      }
    } else {
      this.isInitialDelayWaitFinished = false;
    }
  }

  get isContentHidden(): boolean {
    if (!this.hideContentWhenLoading) {
      return false;
    }
    return this.isLoading && this.isInitialDelayWaitFinished;
  }
}

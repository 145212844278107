import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';
import { ApplicationService } from '../../../application/application.service';
import {
  MiniBundleDataTableData,
  MiniBundleDataTableRow,
} from '../../../global/components/mini-bundle-data-table/mini-bundle-data-table.component';
import { ApplicationBundleData } from '@squidcloud/internal-common/types/bundle-data.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type DatabaseInput = {
  integrationId: string;
  collectionName?: string;
};

type ApiInput = {
  integrationId: string;
  endpointId?: string;
};

@Component({
  selector: 'mini-backend-functions',
  templateUrl: './mini-backend-functions.component.html',
  styleUrls: ['./mini-backend-functions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MiniBackendFunctionsComponent implements OnChanges {
  @Input() title!: string;
  @Input() databaseInput?: DatabaseInput;
  @Input() apiInput?: ApiInput;

  expanded = false;

  readonly bundleDataObs = this.applicationService.observeBundleData().pipe(filter(Boolean));
  readonly triggersDataSubject = new BehaviorSubject<MiniBundleDataTableData | undefined>(undefined);
  readonly transformersDataSubject = new BehaviorSubject<MiniBundleDataTableData | undefined>(undefined);
  readonly securityRulesDataSubject = new BehaviorSubject<MiniBundleDataTableData | undefined>(undefined);

  constructor(private readonly applicationService: ApplicationService) {
    this.bundleDataObs.pipe(takeUntilDestroyed()).subscribe(metadata => {
      this.updateData(metadata);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['apiInput'] || changes['databaseInput']) {
      this.updateData(this.applicationService.bundleData);
    }
  }

  private updateData(metadata: ApplicationBundleData | undefined | null): void {
    this.securityRulesDataSubject.next(metadata ? this.getSecurityRulesData(metadata) : undefined);
    this.triggersDataSubject.next(metadata ? this.getTriggersData(metadata) : undefined);
    this.transformersDataSubject.next(metadata ? this.getTransformersData(metadata) : undefined);
  }

  getSecurityRulesData(metadata: ApplicationBundleData): MiniBundleDataTableData | undefined {
    const title = 'Security rules';
    const iconName = 'security_rules_icon';

    if (this.databaseInput) {
      const { integrationId, collectionName } = this.databaseInput;
      const map = collectionName
        ? metadata.databases?.[integrationId]?.collections?.[collectionName]?.security
        : metadata.databases?.[integrationId]?.security;

      if (!map) return undefined;

      const rows: Array<MiniBundleDataTableRow> = [];
      for (const [action, functionNames] of Object.entries(map)) {
        for (const functionName of functionNames) {
          const columns: Array<string> = [action, functionName];
          rows.push({ columns });
        }
      }
      return {
        title,
        iconName,
        headerRow: ['Action', 'Function name'],
        rows,
      };
    } else if (this.apiInput) {
      const { integrationId, endpointId } = this.apiInput;
      const fns = endpointId
        ? metadata.apis?.[integrationId]?.endpoints?.[endpointId]?.security
        : metadata.apis?.[integrationId]?.security;

      if (!fns) return undefined;

      const rows = fns.map(functionName => ({ columns: [functionName] }));
      return {
        title,
        iconName,
        headerRow: ['Function name'],
        rows,
      };
    } else {
      return undefined;
    }
  }

  getTriggersData(metadata: ApplicationBundleData): MiniBundleDataTableData | undefined {
    if (!this.databaseInput) return undefined;

    const { integrationId, collectionName } = this.databaseInput;

    const rows: Array<MiniBundleDataTableRow> = Object.entries(metadata.triggers)
      .filter(
        ([, triggerMetadata]) =>
          triggerMetadata.integrationId === integrationId && triggerMetadata.collectionName === collectionName,
      )
      .map(([triggerId, triggerMetadata]) => {
        const columns: Array<string> = [triggerId, triggerMetadata.functionName];
        return { columns };
      });

    if (!rows.length) return undefined;

    return {
      iconName: 'triggers_icon',
      headerRow: ['ID', 'Function name'],
      rows,
      title: 'Triggers',
    };
  }

  getTransformersData(metadata: ApplicationBundleData): MiniBundleDataTableData | undefined {
    if (!this.databaseInput) return undefined;

    const { integrationId, collectionName } = this.databaseInput;

    const map = collectionName
      ? metadata.databases?.[integrationId]?.collections?.[collectionName]?.transform
      : metadata.databases?.[integrationId]?.transform;

    if (!map) return undefined;

    const rows = Object.entries(map).map(([transformAction, transformMetadata]) => {
      const columns: Array<string> = [transformAction, transformMetadata.serviceFunction];
      return { columns };
    });
    return {
      iconName: 'transformers_icon',
      headerRow: ['Action', 'Function name'],
      rows,
      title: 'Transformers',
    };
  }
}

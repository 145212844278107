import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OnboardingService } from '../onboarding.service';
import { ONBOARDING_PAGE_PATH } from '@squidcloud/console-web/app/onboarding/onboarding.types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding-done',
  templateUrl: './onboarding-done.component.html',
  styleUrls: ['./onboarding-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OnboardingDoneComponent {
  constructor(
    private readonly onboardingService: OnboardingService,
    router: Router,
  ) {
    if (!this.onboardingService.onboardingOrganizationId) {
      void router.navigate([ONBOARDING_PAGE_PATH]);
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';
import { BehaviorSubject } from 'rxjs';
import { IntegrationService } from '../integration.service';
import {
  AllIntegrations,
  AvailableCategories,
  Categories,
  CategoryAndIntegrations,
  CategoryContent,
  ComingSoonIntegrations,
  IntegrationContent,
} from '../utils/content';
import { IntegrationCategory } from '@squidcloud/console-common/types/integration.types';
import { groupBy } from '@squidcloud/internal-common/utils/object';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'available-integrations',
  templateUrl: './available-integrations.component.html',
  styleUrls: ['./available-integrations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AvailableIntegrationsComponent {
  readonly availableCategories = AvailableCategories;

  readonly selectedCategorySubject = new BehaviorSubject<string>(AvailableCategories[0].key);
  filterText = '';

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly integrationService: IntegrationService,
    private readonly router: Router,
    private readonly analytics: AnalyticsService,
  ) {
    activatedRoute.params.pipe(takeUntilDestroyed()).subscribe(params => {
      this.selectedCategorySubject.next(params['selectedCategoryKey'] || AvailableCategories[0].key);
    });
  }

  getIntegrations(key: string): Array<IntegrationContent> {
    let result: Array<IntegrationContent>;
    switch (key) {
      case 'all':
        result = AllIntegrations;
        break;
      case 'coming-soon':
        result = ComingSoonIntegrations;
        break;
      default:
        result = AllIntegrations.filter(integration => integration.category === key);
        break;
    }
    result = [...result].sort((i1, i2) => i1.name.localeCompare(i2.name));
    return result;
  }

  getCategoryAndIntegrationsArray(key: string, filterText: string): Array<CategoryAndIntegrations> {
    const integrations = this.getIntegrations(key);
    const filteredIntegrations = integrations.filter(integration => {
      if (!filterText) return true;
      return (
        integration.name.toLowerCase().includes(filterText.toLowerCase()) ||
        integration.description.toLowerCase().includes(filterText.toLowerCase()) ||
        integration.category.toLowerCase().includes(filterText.toLowerCase())
      );
    });
    const groupedIntegrations = groupBy(filteredIntegrations, integration => integration.category);
    return Object.entries(groupedIntegrations).map(([key, value]) => {
      const categoryContent: CategoryContent = { ...Categories[key as IntegrationCategory], key };
      const res: CategoryAndIntegrations = { category: categoryContent, integrations: value };
      return res;
    });
  }

  get currentApplication(): CpApplication {
    return this.integrationService.currentApplication;
  }

  async handleSelectChanged(key: string): Promise<void> {
    await this.router.navigate(['/application', this.currentApplication.appId, 'integrations', 'available', key]);
  }

  handleAddIntegration(type: string): void {
    void this.analytics.track('CTA Clicked', { title: 'Add Integration', type, location: 'available-integrations' });
  }

  onFiltersChanged(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.filterText = input.value;
  }

  openDocs(docsUrl: string): void {
    // Accepts string or undefined
    window.open(docsUrl, '_blank');
  }

  showRequestIntegrationDialog(name?: string): void {
    this.integrationService.showRequestIntegrationDialog(name);
  }
}

import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';

const CHAT_WEB_COMPONENT_NAME = 'squid-chat-widget-with-fab-button';

@Component({
  selector: 'rudder-chat',
  templateUrl: './rudder-chat.component.html',
  styleUrls: ['./rudder-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RudderChatComponent implements AfterViewChecked {
  /**
   * Tracks if the web-component for chat is loaded.
   * We do not show the chat until the web component is loaded to void browser to handle it like a regular HTML.
   */
  isChatWebElementLoaded = false;

  @ViewChild('calendlyElementRef') calendlyElementRef?: ElementRef;

  /**
   * Tracks if an observer for calendly element is set.
   * The observer can only be set after 'isChatWebElementLoaded'.
   */
  private isCalendlyObserverInstalled = false;

  /**
   * If true, the calendly iframe is created in DOM.
   * The iframe is created (and Calendly script is loaded and initialized) lazily only when chat widget is opened.
   */
  isCalendlyIframeAddedToDom = false;

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.isChatWebElementLoaded = !!window.customElements.get(CHAT_WEB_COMPONENT_NAME);
    if (!this.isChatWebElementLoaded) {
      window.customElements.whenDefined(CHAT_WEB_COMPONENT_NAME).then(() => {
        this.isChatWebElementLoaded = true;
        this.cdr.markForCheck();
      });
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isCalendlyObserverInstalled && this.calendlyElementRef?.nativeElement) {
      const observer = new IntersectionObserver(entries => {
        for (const entry of entries) {
          if (entry.isIntersecting && !this.isCalendlyIframeAddedToDom) {
            this.isCalendlyIframeAddedToDom = true;
            observer.unobserve(entry.target);
            this.cdr.markForCheck();
            break;
          }
        }
      });
      observer.observe(this.calendlyElementRef.nativeElement);
      this.isCalendlyObserverInstalled = true;
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountService } from '../../../account/account.service';

@Component({
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LogoutComponent {
  constructor(private readonly accountService: AccountService) {
    this.accountService.logout();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QuickStartService, QuickStartTask } from '../quick-start.service';

@Component({
  selector: 'quick-start-fly-out',
  templateUrl: './quick-start-fly-out.component.html',
  styleUrls: ['./quick-start-fly-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class QuickStartFlyOutComponent {
  readonly expandedObs = this.quickStartService.observeExpanded();
  readonly tasksObs = this.quickStartService.observeTasks();

  constructor(private readonly quickStartService: QuickStartService) {}

  getCounts(tasks: Array<QuickStartTask>): { total: number; completed: number } {
    let total = 0;
    let completed = 0;
    for (const task of tasks) {
      total++;
      if (task.completionDate) completed++;
    }
    return { total, completed };
  }

  closeFlyout(): void {
    this.quickStartService.toggleExpanded();
  }

  getNextTask(tasks: Array<QuickStartTask>): QuickStartTask | undefined {
    return tasks.find(t => !t.completionDate);
  }

  getOpenTasks(tasks: Array<QuickStartTask>): Array<QuickStartTask> {
    const nextTask = this.getNextTask(tasks);
    return tasks.filter(t => !t.completionDate && t.id !== nextTask?.id);
  }

  getCompletedTasks(tasks: Array<QuickStartTask>): Array<QuickStartTask> {
    return tasks.filter(t => t.completionDate);
  }

  async markTaskComplete(nextTask: QuickStartTask): Promise<void> {
    await this.quickStartService.markTaskComplete(nextTask.id);
  }

  getRudderImageSvg(counts: { total: number; completed: number }): string {
    const numOfRudderImages = 10;
    const rudderImageNumber = Math.min(
      numOfRudderImages,
      Math.floor((counts.completed / counts.total) * numOfRudderImages) + 1,
    );
    return `quickstart_rudder_${rudderImageNumber}`;
  }

  taskClicked(task: QuickStartTask): void {
    this.quickStartService.taskClicked(task);
  }
}
